/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { usePhrases } from '@frontend/utils/usePhrases';
import BarChartIcon from '@material-ui/icons/BarChart';
import useDateTimeFormat    from '@frontend/utils/useDateTimeFormat';
import PartsChartModal from '@frontend/modules/machine/PartsChartModal';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  machinePulse: {
    padding: theme.spacing(2),
    width: '100%',
    textAlign: 'left',
    marginBottom: theme.spacing(2),
  },
  green: {
    color: '#3bb273',
  },
  red: {
    color: '#d62828',
  },
  yellow: {
    color: '#FED766',
  },
  chart: {
    padding: theme.spacing(2),
  },
  push: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  lastTableCell: {
    borderBottom: 'none',
  },
  topCardsContainer: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(0),
  },
  cardCell: {
    padding: theme.spacing(1.5),
  },
  stickyBarContainer: {
    marginTop: theme.spacing(0),
  },
  pulseGrid: {
    marginBottom: theme.spacing(3),
  },
  sticky: {
    position: 'fixed',
    left: 0,
    top: 64,
    width: '100%',
    zIndex: theme.zIndex.appBar,
    [theme.breakpoints.down('sm')]: {
      top: 56,
    },

  },
  backdrop: {
    zIndex: 1,
    position: 'fixed',
    margin: 'auto',
  },

  item1: {
    order: 1,
    [theme.breakpoints.down('sm')]: {
      order: 2,
    },
  },

  item2: {
    order: 2,
    [theme.breakpoints.down('sm')]: {
      order: 3,
    },
  },

  item3: {
    order: 3,
    [theme.breakpoints.down('sm')]: {
      order: 4,
    },
  },

  item4: {
    order: 4,
    [theme.breakpoints.down('sm')]: {
      order: 1,
      position: 'fixed',
      left: 0,
      top: 56,
      width: '100%',
      zIndex: theme.zIndex.appBar,

    },
  },

  item5: {
    order: 1,
    [theme.breakpoints.down('sm')]: {
      order: 2,
    },
  },

  item6: {
    order: 2,
    [theme.breakpoints.down('sm')]: {
      order: 1,
    },
  },
  barChartIcon: {
    cursor: 'pointer',
  },
}));

export default function PartsInfoCard({ machine, setMachineActualCount }) {
  const classes = useStyles();
  const phrases = usePhrases().phrases();
  const machineStates = useSelector((state) => state.machineStates);
  const [partsInfo, setPartsInfo] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const {
    toLocaleNumber,
  } = useDateTimeFormat();

  useEffect(() => {
    if(!_.isEmpty(machineStates)){
      setPartsInfo(machineStates[machine.id]?.partsInfo);      
      if(setMachineActualCount){
        setMachineActualCount(machineStates[machine.id]?.partsInfo?.actualCount ?? 0);
      } 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machineStates]);
  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell className={classes.cardCell} align="left">
            <Typography variant="body1" align="left">
              {phrases.modules.performance.parts}
            </Typography>
          
          </TableCell>
          <TableCell className={classes.cardCell} align="right">
          {machine.config?.partsChart && 
            <BarChartIcon onClick={()=> {setModalOpen(true)}} fontSize={'medium'} className={classes.barChartIcon} />
          }
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>

        <TableRow>
          <TableCell className={classes.cardCell} align="left">
            {phrases.modules.performance.partsProduced}
            {' '}
            /
            {' '}
            {phrases.modules.performance.target}
          </TableCell>
          <TableCell className={classes.cardCell} align="right">
            {!_.isEmpty(partsInfo) ? `${toLocaleNumber(Math.round(partsInfo?.actualCount ?? 0))} / ${toLocaleNumber(Math.round(partsInfo.targetCount))}` : '- / -'}
            {/* {getMachineActualCount(machine,machineStates)/getMachineIdealCount(machine, machineStates)} */}
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell className={classes.cardCell} align="left">
            {!_.isEmpty(partsInfo) && partsInfo.deviation > 0 ? phrases.modules.performance.partsSurplus : phrases.modules.performance.partsMissed}
          </TableCell>
          <TableCell className={classes.cardCell} align="right">
            {!_.isEmpty(partsInfo) ? toLocaleNumber(Math.round(Math.abs(partsInfo.deviation))) : '-'}
          </TableCell>
        </TableRow>

      </TableBody>
      
    {machine.config?.partsChart && 
    <PartsChartModal 
      machineId={machine.id}
      modalOpen={modalOpen}
      targetCycleTime={machine.config.targetCycleTime}
      setModalOpen={setModalOpen}
    />
  }
    </>
  );
}
