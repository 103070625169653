const useMachineFormCustomValues = (machineValues, setMachineValues) => {

    const handleChangeCheckbox = (event) => {
        const { name } = event.target;
        let config = machineValues.config || {};
        config[name] = event.target.checked.toString();     // Backward compatibility 
        setMachineValues({...machineValues, config})
    }

    const handleChangeValue = (event) => {
        const { name, value } = event.target;
        let config = machineValues.config;
        config[name] = value;
        setMachineValues({...machineValues, config})
    }
    
    return {
        handleChangeValue, 
        handleChangeCheckbox,
    };
}
 
export default useMachineFormCustomValues;