import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { usePhrases }             from '@frontend/utils/usePhrases';
import ConfirmationModal from '@frontend/components/ConfirmationModal';
import { makeStyles } from '@material-ui/core/styles';
import StyledSelect                   from '@frontend/components/StyledSelect';
import useApi from '@frontend/utils/useApi';
import useAlert from '@frontend/hooks/useAlert';
import {
    Button,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    Typography
} from '@material-ui/core';
import _ from 'lodash';


const useStyles = makeStyles((theme) => ({
    root: {
      margin: theme.spacing(1),
    },
    formControl: {
      marginBottom: theme.spacing(2),
    },
    firstRow: {
      fontWeight: 'bold',
    },
    btnRight: {
        float: 'right',
        display: 'flex',
    },
}));

const ReviewFlowPickMachine = (props) => {

    const {
        job,
        setSelectedMachine
    } = props;

    const classes = useStyles();
    const phrases = usePhrases().phrases();
    const api = useApi();
    const { createAlert } = useAlert();

    const selectedStructure = useSelector((state) => state.structure);
    const [machine, setMachine] = useState({});
    const [machineNames, setMachineNames ] = useState([]);    

    const confirmMachine = () => {
        api(`/api/machines/${machine.id}`, {
            method: 'get',
        })
        .then((response) => {
            setSelectedMachine(response.data);
        })
        .catch((error) => {
            createAlert(phrases.modules.orders.reviewFlow.noMachine, 'error');
        });
    }

    useEffect(() => {
        if(!_.isEmpty(job)){
            let foundMachine = machineNames.find((machine) => machine.id === job?.intendedMachine?.id);  
            if(foundMachine !== undefined){
                setMachine(foundMachine);    
            } else {
                setMachine(machineNames[0]);
            }
        }
        else{
            setMachine(machineNames[0]);
        }
    }, [machineNames]);

    useEffect(() => {
        if(!_.isEmpty(selectedStructure) ){         
            // Extract the machine IDs from the selected structure
            let machineNames = selectedStructure?.machines.map((machine) => {
                return {id: machine.id, name: machine.name, businessId: machine.businessId};
            });            

            // Add the intended Machine of the Job if present
            if(!_.isEmpty(job)){
                let foundMachine = machineNames.find((machine) => machine.id === job?.intendedMachine?.id);  
                if(foundMachine === undefined){
                    machineNames = [...machineNames, {id: job?.intendedMachine?.id, name: job?.intendedMachine?.name, businessId: job?.intendedMachine?.businessId}];
                }
                let jobMachine = {id: job?.intendedMachine?.id, name: job?.intendedMachine?.name, businessId: job?.intendedMachine?.businessId}                
                setMachine(jobMachine)
            }
            setMachineNames(machineNames);
        }
    }, [selectedStructure]);

    
    // Fetch the Job for the machine
    const handleMachineChange = (event) => {
        const { value } = event.target;            
        setMachine(value);
    }
 
    return ( <>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
                <Typography variant="h6" component="h6">
                    {phrases.modules.orders.reviewFlow.selectMachine}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>  
                <StyledSelect
                    label={phrases.modules.order_export.machine}
                    id="machine-field"
                    name="machine"
                    value={machine}
                    onChange={handleMachineChange}
                    data={machineNames}
                    required
                    plain
                />  
            </Grid>
            <Grid item xs={12} sm={12}> 
                <Button onClick={confirmMachine} disabled={_.isEmpty(machine)} variant="contained" color="primary" autoFocus className={classes.btnRight}>
                    {phrases.modules.orders.reviewFlow.confirmMachine}
                </Button>
            </Grid>
        </Grid>
    </> );
}
 
export default ReviewFlowPickMachine;