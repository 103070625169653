import React, { useState } from 'react';
import {
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Link from '@material-ui/icons/Link';
import useSecurity from '@frontend/utils/useSecurity';
import { usePhrases } from '@frontend/utils/usePhrases';
import Modal from '@frontend/components/Modal';
const useStyles = makeStyles((theme) => ({
    
    createButton: {
      borderRadius: '10%',
      padding: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    boldText: {
      fontWeight: 800,
    },
    lightText: {
        marginLeft: theme.spacing(1),
      fontWeight: 100,
      fontSize: '1.0rem',
    },
    modalText: {
      fontWeight: 100,
      fontSize: '1.2rem',
    },
    centerInput: {
      display: 'flex',
      justifyContent: 'center',
    },
  }));
const EasyLoginCreatorButton = () => {

    const classes = useStyles();
    const phrases = usePhrases().phrases();
    const { createAuthQueryParameter } = useSecurity();
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

    const handleCreateURL = () => {
        setConfirmationModalOpen(true);
    }

    const createURL = () => {
        const query = createAuthQueryParameter();
        let fullPath = window.location.href;
        if(fullPath[fullPath.length-1] === '/'){
            fullPath = fullPath.slice(0, -1);
        }
        return fullPath + `?${query}`;
    }

    const confirmationModalText = () => {
        let authURL = createURL();
        return (<>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='body1' align='center' className={classes.modalText}>{phrases.modal.loginURLText}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.centerInput}>
              <TextField variant="outlined" value={authURL} readOnly style={{"width": "75%"}}/>
            </Grid>
          </Grid>
        </>)
    }

    const handleCloseConfirmationModal = () => {
        setConfirmationModalOpen(false);
    }

    return (<>
     <IconButton
        aria-label="Create Easy Login URL"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        className={classes.createButton}
        onClick={handleCreateURL}
        color="inherit"
      >
        <Link />
        <span className={classes.lightText}>{phrases.misc.createAuthURL}</span>
      </IconButton>      
      <Modal
        modalTitle={phrases.modal.loginURL}
        content={confirmationModalText()}
        open={confirmationModalOpen}
        handleClose={handleCloseConfirmationModal}
        callback={handleCloseConfirmationModal}
      />
      </>
    );
}
 
export default EasyLoginCreatorButton;