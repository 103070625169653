import React from 'react';
import {
    Divider,
    Grid,
    InputAdornment,
    Typography,
    TextField,
    
} from '@material-ui/core';

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {
    MuiPickersUtilsProvider,
    DateTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import useStatesFilter      from '@frontend/hooks/useStatesFilter';
import { usePhrases }       from '@frontend/utils/usePhrases';
import useDateTimeFormat    from '@frontend/utils/useDateTimeFormat';

import { 
    makeStyles
  } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    textCenter: {
        textAlign: 'center',
        margin: 'auto',
    },
    heading: {
        textAlign: 'center',
        margin: 'auto',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    },
    
}));
const ReviewFlowSummary = (props) => {
    const { 
        qualityReports,
        activation,
        scrapPresent,
    } = props;
    const classes = useStyles();
    const { scrapTypeText } = useStatesFilter();
    const phrases = usePhrases().phrases();
    const { getLocale } = useDateTimeFormat();

    return (<>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
                <Typography variant="h5" component="h5" className={classes.heading}>
                    {phrases.modules.orders.reviewFlow.summary}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography variant="h6" component="h6" color="textSecondary" className={classes.textCenter}>
                    {phrases.modules.orders.reviewFlow.summaryText}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="button" display="block" color="textSecondary">
                    {phrases.modules.orders.reviewFlow.activation}
                </Typography>
                <Divider />
            </Grid>
            <Grid item xs={12} sm={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={1}>                        
                        <TextField
                            id="summary-machine"
                            name="machine"
                            label={phrases.modules.order_export.machine}
                            variant="outlined"
                            type="text"
                            fullWidth 
                            disabled                           
                            value={activation.machine?.businessId}
                            margin="dense"
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextField
                            id="summary-user"
                            name="user"
                            label={phrases.modules.order_export.user}
                            variant="outlined"
                            type="text"
                            fullWidth 
                            disabled                           
                            value={activation.user.email}
                            margin="dense"
                        />
                    </Grid>
                    <Grid item xs={12} sm={1}>
                        <TextField
                            id="summary-handCount"
                            name="handCount"
                            label={phrases.modules.order_export.handCount}
                            variant="outlined"
                            type="number"
                            fullWidth 
                            disabled                           
                            value={activation.handCount}
                            margin="dense"
                            notched={true}
                            InputLabelProps={{
                              shrink: true,
                            }}
                        />
                    </Grid>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale()}>
                        <Grid item xs={12} sm={2}>
                            <DateTimePicker
                                ampm={false}
                                disableFuture
                                openTo="hours"
                                inputVariant="outlined"
                                margin="dense"
                                format={phrases.timespanSelection.timeFormat}
                                fullWidth
                                minutesStep={1}
                                maxDate={activation.time}
                                InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccessTimeIcon />
                                    </InputAdornment>
                                ),
                                }}
                                value={activation.time}
                                label={phrases.timespanSelection.from}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <DateTimePicker
                                ampm={false}
                                disableFuture
                                openTo="hours"
                                inputVariant="outlined"
                                margin="dense"
                                format={phrases.timespanSelection.timeFormat}
                                fullWidth
                                minutesStep={1}
                                maxDate={activation.endTime}
                                InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccessTimeIcon />
                                    </InputAdornment>
                                ),
                                }}
                                value={activation.endTime}
                                label={phrases.timespanSelection.to}
                                disabled
                            />
                        </Grid>
                    </MuiPickersUtilsProvider>
                    <Grid item xs={12} sm={2}>
                        <TextField
                            id="summary-job"
                            name="job"
                            label={phrases.modules.order_export.job}
                            variant="outlined"
                            type="text"
                            fullWidth 
                            disabled                           
                            value={activation.job.businessJobId}
                            margin="dense"
                            notched={true}
                            InputLabelProps={{
                              shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextField
                            id="summary-product"
                            name="product"
                            label={phrases.modules.order_export.product}
                            variant="outlined"
                            type="text"
                            fullWidth 
                            disabled                           
                            value={activation.job.product.name}
                            margin="dense"
                            notched={true}
                            InputLabelProps={{
                              shrink: true,
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {scrapPresent && (<>
            <Grid item xs={12}>
                <Typography variant="button" display="block" color="textSecondary">
                    {phrases.modules.orders.reviewFlow.qualityReports}
                </Typography>
                <Divider />
            </Grid>
            <Grid item xs={12} sm={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Grid container spacing={2}>   
                            <Grid item xs={12} sm={6}>                        
                                <TextField
                                    id="summary-machine"
                                    name="machine"
                                    label={phrases.modules.quality.scrapType}
                                    variant="outlined"
                                    type="text"
                                    fullWidth 
                                    disabled                           
                                    value={scrapTypeText(qualityReports.identifier)}
                                    margin="dense"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="summary-user"
                                    name="user"
                                    label={phrases.modules.quality.count}
                                    variant="outlined"
                                    type="number"
                                    fullWidth 
                                    disabled                           
                                    value={qualityReports.value}
                                    margin="dense"
                                    notched={true}
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                />
                            </Grid>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale()}>
                                <Grid item xs={12} sm={6}>
                                    <DateTimePicker
                                        ampm={false}
                                        disableFuture
                                        openTo="hours"
                                        inputVariant="outlined"
                                        margin="dense"
                                        format={phrases.timespanSelection.timeFormat}
                                        fullWidth
                                        minutesStep={1}
                                        maxDate={qualityReports.startTime}
                                        InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AccessTimeIcon />
                                            </InputAdornment>
                                        ),
                                        }}
                                        value={qualityReports.startTime}
                                        label={phrases.timespanSelection.from}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <DateTimePicker
                                        ampm={false}
                                        disableFuture
                                        openTo="hours"
                                        inputVariant="outlined"
                                        margin="dense"
                                        format={phrases.timespanSelection.timeFormat}
                                        fullWidth
                                        minutesStep={1}
                                        maxDate={qualityReports.endTime}
                                        InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AccessTimeIcon />
                                            </InputAdornment>
                                        ),
                                        }}
                                        value={qualityReports.endTime}
                                        label={phrases.timespanSelection.to}
                                        disabled
                                    />
                                </Grid>
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="summary-handCount"
                            name="handCount"
                            label={phrases.forms.reason.misc.note}
                            variant="outlined"
                            type="text"
                            multiline
                            minRows={3}
                            fullWidth 
                            disabled                           
                            value={qualityReports.note}
                            margin="dense"
                        />
                    </Grid>
                </Grid>                    
            </Grid>
            </>)}
            
        </Grid>

    </>);
}
 
export default ReviewFlowSummary;