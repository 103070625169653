import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { 
  makeStyles
} from '@material-ui/core/styles';
import {
  Grid,
  Paper,
} from '@material-ui/core';

import { ROUTES }                 from '@frontend/constants';
import ReviewFlowContent          from '@frontend/modules/orders/ReviewFlowContent';
import ReviewFlowPickJob          from '@frontend/modules/orders/components/ReviewFlowPickJob';
import ReviewFlowPickMachine      from '@frontend/modules/orders/components/ReviewFlowPickMachine';
import ReviewFlowPickWorkers      from '@frontend/modules/orders/components/ReviewFlowPickWorkers';
import { setMachineInUse }        from '@frontend/utils/UIActions';
import ReviewFlowOverview         from './components/ReviewFlowOverview';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(0),
      },
    },
    container: {
      marginTop: theme.spacing(2),
      justifyContent: 'center',
    },
    paper: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
  }));
const ReviewFlowView = () => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [job, setJob] = useState({});
  const [selectedMachine, setSelectedMachine] = useState({});
  const [contentOpen, setContentOpen] = useState(true);
  const [workers, setWorkers] = useState([]);
 
  const handleCloseReviewFlow = () => {
    // Set selected Machine in Redux 
    dispatch(setMachineInUse(selectedMachine.id));
    setContentOpen(false);
    // history.push(`${ROUTES.ORDERS_EXPORT_VALIDATION}/${selectedMachine.structure}`);
    // setWorkers([]);
  }
    return ( <>
        <div className={classes.root}>
            <Grid container spacing={2} className={classes.container}>
              {contentOpen && (
              <Grid item xs={6}>
                <Paper
                  className={classes.paper}
                  square
                  variant="outlined"
                >
                  {_.isEmpty(job) ?                    
                    <ReviewFlowPickJob 
                      job={job}
                      setJob={setJob}
                    />
                    : _.isEmpty(selectedMachine) ?
                      <ReviewFlowPickMachine
                        job={job}
                        setSelectedMachine={setSelectedMachine}
                      />
                      : _.isEmpty(workers) ?            
                        <ReviewFlowPickWorkers
                          setWorkers={setWorkers}
                        />
                        : contentOpen && 
                          <ReviewFlowContent
                            handleClose={handleCloseReviewFlow}
                            job={job}
                            selectedMachine={selectedMachine}
                            workers={workers}
                          /> 
                  }
                            
                </Paper>               
              </Grid>)}
              {!contentOpen && (
                <Grid item xs={10}>
                  <ReviewFlowOverview
                    structureId={selectedMachine.structure}
                    selectedMachine={selectedMachine}
                  />
                </Grid>
                )}
            </Grid>
        </div>
    </> );
}
 
export default ReviewFlowView;