/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { createBrowserHistory } from 'history';
import useSecurity from '../utils/useSecurity';
import useMatomo from '../utils/useMatomo';

/**
 * Wrapper component. Any Route(react-router-dom) that is wrapped
 * in this component, will require the authenticated user in order to render.
 */
const PrivateRoute = ({ component: Component, ...props }) => {
  
  const { loggedIn, setTokenAndLogin } = useSecurity();
  // Check if a valid bearer token is passed in the URL
  let urlParams = new URLSearchParams(props.location.search);
  if (urlParams.get('bearer')) {
    const token = urlParams.get('bearer');
    urlParams.delete('bearer');
    setTokenAndLogin(token, props.location.pathname+"?"+urlParams.toString());    
  }

  const user = useSelector((state) => state.user);
  const history = createBrowserHistory();
  const { piwik } = useMatomo(7, user);
  return (
    <Route
      history={piwik.connectToHistory(history)}
      {...props}
      render={(_props) => (
        user && loggedIn()
          ? <Component {..._props} />
          : <Redirect to="/sign-in" />
      )}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default PrivateRoute;
