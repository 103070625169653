/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import Modal from '@frontend/components/Modal';
import useAlert from '@frontend/hooks/useAlert';
import useCurrentBreakpoint from '@frontend/hooks/useCurrentBreakpoint';
import useStatesFilter from '@frontend/hooks/useStatesFilter';
import useDateTimeFormat from '@frontend/utils/useDateTimeFormat';
import useApi from '@frontend/utils/useApi';
import { usePhrases } from '@frontend/utils/usePhrases';
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  Paper,
  Popper,
  ClickAwayListener,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  axisBottom, 
  drag, 
  scaleTime, 
  select,
} from 'd3';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: '100%',
  },
  formBtn: {
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
  },
  formButtons: {
    flexGrow: 1,
    display: 'flex',
    marginTop: theme.spacing(3),
  },
  loadingBtnIcon: {
    marginLeft: theme.spacing(1),
  },
  inputField: {
    width: '100%',
  },
  expand: {
    marginLeft: 'auto',
  },
  bold: {
    fontWeight: 900,
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  statusTitle: {
    marginLeft: theme.spacing(1),
  },
  reasonTitle: {
    marginBottom: theme.spacing(1),
  },
  pulseWrapper: {
    marginTop: theme.spacing(1),
  },
  backdrop: {
    zIndex: 1,
    position: 'fixed',
    margin: 'auto',
  },
  popupWrapper: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(2),
    zIndex: 9999,
  },
  pulsePopup: {
    paddingTop: 0,
    padding: theme.spacing(1),
    paddingBottom: 0,
    marginBottom: 0,
    backgroundColor: theme.palette.background.paper,
  },
}));

const SplitStateModal = (props) => {
  const classes = useStyles();
  const {
    open,
    selectedState,
    handleCloseModal,
    refreshPulse,
  } = props;
  const api = useApi();
  const phrases = usePhrases()
    .phrases();
  const currentBreakpoint = useCurrentBreakpoint();
  const { stateColor, stateText } = useStatesFilter();
  const { formatTime } = useDateTimeFormat();
  const { createAlert } = useAlert();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [resultStates, setResultStates] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const svgRef = useRef();
  const svgWrapperRef = useRef();

  const handleCloseModalClick = () => {
    handleCloseModal();
  };

  const onModalClose = () => {
    handleCloseModal();
  };

  const onModalOpen = () => {
    renderDynamicState();
  };

  function roundTo60(x) {
    return Math.ceil(x / 60) * 60;
  }

  const roundToMinute = (date) => {
    const time = moment(date);
    // Sometimes we get an offset by 1 sec while dragging time block on the timeline
    // Therefore this check is here
    if (time.get('seconds') > 30) return time.add(1, 'minutes').startOf('minute');
    return time.startOf('minute');
  };


  const splitOriginalStateInMemory = (_selectedState) => {
    const stateDurationInSec = moment(_selectedState.endTime).diff(moment(_selectedState.startTime), 'seconds');
    const earliestResultStateDurationInSec = roundTo60(stateDurationInSec / 2);
    const subsequentResultStateDurationInSec = (stateDurationInSec - earliestResultStateDurationInSec);
    const earlisetResultState = {
      startTime: _selectedState.startTime,
      endTime: moment(_selectedState.startTime).add(earliestResultStateDurationInSec, 'seconds').toISOString(),
      state: _selectedState.state,
      machineType: _selectedState.machineType,
      machineId: _selectedState.machineId,
      duration: earliestResultStateDurationInSec / 60,
    };
    const subsequentResultState = {
      startTime: earlisetResultState.endTime,
      endTime: moment(earlisetResultState.endTime).add(subsequentResultStateDurationInSec, 'seconds').toISOString(),
      state: _selectedState.state,
      machineType: _selectedState.machineType,
      machineId: _selectedState.machineId,
      duration: subsequentResultStateDurationInSec / 60,
    };

    setResultStates([earlisetResultState, subsequentResultState]);
  };

  const handleSubmit = (_event) => {
    if (_event) {
      _event.preventDefault();
    }
    setIsSubmitting(true);
    api('/api/machines/split-state', {
      data: { resultStates },
      method: 'post',
    })
      .then((response) => {
        if (response.status === 200) {
          if (refreshPulse) refreshPulse();
          createAlert(phrases.modules.machine.splitStateSuccessful, 'success');
          setIsSubmitting(false);
          handleCloseModal();
        }
      }).catch((error) => {
        const errorMessage = phrases.forms.machine.errors.genericError;
        if (error.response) {
          createAlert(errorMessage, 'error');
          setIsSubmitting(false);
          handleCloseModal();
        }
      });
  };

  const handleClose = () => {
    setTooltipOpen(false);
    setAnchorEl(null);
  };

  // will be called initially and on every data change
  const renderDynamicState = () => {
    const svg = select(svgRef.current);
    const svgWrapper = svgWrapperRef.current;
    let wrapperWidth = 0;
    if (svgWrapper) wrapperWidth = svgWrapper.clientWidth;
    // Erase chart so it can be redrawn with new data
    svg.selectAll('rect')
      .remove();
    svg.selectAll('circle')
      .remove();
    svg.selectAll('path')
      .remove();
    // If the wrapper has no width an height
    if (!selectedState) return;

    // Min and Max time on the timeline
    const minTime = new Date(selectedState.startTime);
    const maxTime = new Date(selectedState.endTime);

    // x scale, time scale
    const xScale = scaleTime()
      .domain([minTime, maxTime])
      .range([0, 400]);

    // x axis
    const xAxisBottom = axisBottom(xScale)
      .tickValues([minTime, new Date(resultStates[0]?.endTime), maxTime]);

    // position x axis
    const xAxis = svg.select('.x-axis')
      .transition()
      .duration(250)
      .style('transform', `translate(${wrapperWidth / 2 - 200}px,${50 - 7}px)`)
      .call(xAxisBottom);

    function dragStarted(event, d) {
      setAnchorEl(this);
      setTooltipOpen(true);
      xAxisBottom.tickValues([minTime, maxTime]);
            
      select(this)
        .attr('stroke', 'black');
      const pos = this.getBBox();
      if (this.initialX === undefined || this.initialX == null) {
        this.initialX = pos.x + pos.width / 2 + event.dx;
        this.currentX = pos.x + pos.width / 2 + event.dx;
      }
      this.dragged = 0;
      select('#tooltipContent').html(`<span>${formatTime(moment(xScale.invert(this.currentX)))}</span>`);
    }

    function dragging(event, d) {
      let wr = wrapperWidth / 2 - 200;
      const snapDistance = xScale(moment().add(1, 'minutes').startOf('minute')) - xScale(moment().startOf('minute'));
      const smallestStateLen = xScale(moment().add(1, 'minutes').startOf('minute')) - xScale(moment().startOf('minute'))
      this.dragged += event.dx;

      // Check if the rect is dragged at least the snap distance
      if (Math.abs(this.dragged) < snapDistance) return;
      this.currentX += (Math.round(this.dragged / snapDistance) * snapDistance);
      this.dragged -= (Math.round(this.dragged / snapDistance) * snapDistance);

      if (this.currentX < smallestStateLen) {
        this.currentX = smallestStateLen;
      } else if (this.currentX + smallestStateLen > xScale(moment(selectedState.endTime))) this.currentX = xScale(moment(selectedState.endTime)) - smallestStateLen;
      // Transform the rect
      const rect = select(this);
      rect.transition()
        .duration(50).style('transform', `translate(${wr + this.currentX - this.initialX}px, 20px)`);

      const line = select('#line');
      line.transition()
        .duration(50).style('transform', `translate(${wr + this.currentX - this.initialX}px, 0px)`);

      const icon = select('#icon');
      icon.transition()
        .duration(50)
        .style('transform', `translate(${wr + this.currentX - this.initialX + xScale(moment(resultStates[0]?.endTime)) + 12.5}px, ${(50 / 1.21) / 2 - 12}px) rotate(90deg)`);

      select('#popup')
        .transition()
        .duration(50)
        .style('left', `${this.currentX - this.initialX}px`);
      select('#tooltipContent').html(`<span>${formatTime(moment(xScale.invert(this.currentX)))}</span>`);

    }

    function dragEnd(event, d) {
      setTooltipOpen(false);
      setAnchorEl(null);
      const start = roundToMinute(moment(xScale.invert(this.currentX)));
      const modifiedEarliestResultState = {
        ...resultStates[0],
        endTime: moment(start).toISOString(),
        duration: moment(start).diff(resultStates[0].startTime, 'minutes'),
      };
      const modifiedConsequentResultState = {
        ...resultStates[1],
        startTime: moment(start).toISOString(),
        duration: moment(resultStates[1].endTime).diff(start, 'minutes'),
      };
      setResultStates([modifiedEarliestResultState, modifiedConsequentResultState]);
      select(this).attr('stroke', null);
    }

    if (currentBreakpoint()
      .down('sm')) {
      xAxis
        .selectAll('text')
        .style('text-anchor', 'end')
        .attr('dx', '-.8em')
        .attr('dy', '.15em')
        .attr('transform', 'rotate(-45)');
    } else if (currentBreakpoint()
      .up('sm')) {
      xAxis
        .selectAll('text')
        .style('text-anchor', 'end')
        .attr('dx', '1.46em')
        .attr('y', 9)
        .attr('dy', '0.71em')
        .attr('transform', 'rotate(0)');
    }

    // draw states
    svg.selectAll('rect')
      .data(resultStates)
      .enter().append('rect')
      .attr('x', (state) => xScale(moment(state.startTime)))
      .attr('width', (_state) => {
        const stateStart = xScale(new Date(_state.startTime));
        const stateEnd = xScale(new Date(_state.endTime));
        const width = stateEnd - stateStart;
        return width < 0 ? 0 : width;
      })
      .style('transform', `translate(${wrapperWidth / 2 - 200}px, 0px)`)
      .attr('height', `${50 / 1.21}px`)
      .attr('fill', (state) => stateColor(state.state));

    svg.append('rect')
      .attr('x', () => xScale(moment(resultStates[0].endTime)))
      .attr('height', `${50 / 1.21}px`)
      .attr('id', 'line')
      .style('transform', `translate(${wrapperWidth / 2 - 200}px, 0px)`)
      .attr('width', 1)
      .attr('fill', 'whitesmoke')
      .attr('fill-opacity', 1);

    svg.append('circle')      
      .attr('cx', () => xScale(moment(resultStates[0]?.endTime)) + 0.5)
      .style('transform', `translate(${wrapperWidth / 2 - 200}px, ${(50 / 1.21) / 2}px)`)
      .attr('fill', 'whitesmoke')
      .attr('r', 12)
      .attr('cursor', 'ew-resize')
      .call(drag()
        .on('start', dragStarted)
        .on('drag', dragging)
        .on('end', dragEnd)
      );

    svg.append('path')
      .attr('id', 'icon')
      .attr('d', 'M12 5.83L15.17 9l1.41-1.41L12 3 7.41 7.59 8.83 9 12 5.83zm0 12.34L8.83 15l-1.41 1.41L12 21l4.59-4.59L15.17 15 12 18.17z')
      .attr('fill', 'grey')
      .attr('height', 5)
      .style('transform', `translate(${wrapperWidth / 2 + 12 - 200 + xScale(moment(resultStates[0]?.endTime)) + 0.5}px, ${(50 / 1.21) / 2 - 12}px) rotate(90deg)`);
  };

  useEffect(() => {
    splitOriginalStateInMemory(selectedState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedState]);

  useEffect(() => {
    renderDynamicState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultStates]);

  return (
    <>
      <Modal
        modalTitle={phrases.modules.machine.splitState}
        open={open}
        handleClose={onModalClose}
        onEnter={onModalOpen}
        content={(
          <Grid container spacing={1}>
            <form className={classes.root} noValidate onSubmit={handleSubmit} autoComplete="off">
              <Grid item xs={12}>
                <Typography variant="button" display="inline" color="textSecondary">
                  {phrases.forms.reason.misc.machineStatus}
                  :
                </Typography>

                <Typography
                  variant="button"
                  align="left"
                  display="inline"
                  style={{ color: stateColor(selectedState.state) }}
                  className={`${classes.bold} ${classes.uppercase} ${classes.statusTitle}`}
                >
                  {stateText(selectedState.state)}
                </Typography>
                <Divider />
              </Grid>
              <Popper
                id="popup"
                open={tooltipOpen}
                className={classes.popupWrapper}
                anchorEl={anchorEl}
                placement="top"
              >
                <ClickAwayListener onClickAway={(e) => handleClose(e)}>
                  <Paper
                    id="popup-menu"
                    className={classes.pulsePopup}
                    elevation={3}
                    square
                    variant="outlined"
                  >
                    <div className={classes.tooltipContent} id="tooltipContent" />
                  </Paper>
                </ClickAwayListener>
              </Popper>
              <Grid item xs={12} className={classes.pulseWrapper} ref={svgWrapperRef}>
                <svg ref={svgRef} width="100%" height={60}>
                  <g className="x-axis" />
                </svg>
              </Grid>

              <Grid item xs={12}>
                <div className={classes.formButtons}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    className={classes.formBtn}
                    disabled={isSubmitting}
                  >
                    {phrases.forms.shared.buttons.submit}
                    {isSubmitting
                      && (
                        <CircularProgress
                          className={classes.loadingBtnIcon}
                          color="inherit"
                          size={15}
                        />
                      )}
                  </Button>
                  <Button
                    variant="outlined"
                    className={classes.formBtn}
                    onClick={handleCloseModalClick}
                  >
                    {phrases.forms.shared.buttons.close}
                  </Button>
                </div>
              </Grid>
            </form>
          </Grid>
        )}
      />
    </>
  );
};

export default React.memo(
  SplitStateModal,
  (prevProps, nextProps) => prevProps.open === nextProps.open,
);
