/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-shadow */
/* eslint-disable no-console */
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import useApi from '@frontend/utils/useApi';
import useValidateQualityReportForm
  from '@frontend/modules/quality/hooks/useValidateQualityReportForm';
import useAlert from '@frontend/hooks/useAlert';
import { usePhrases } from '@frontend/utils/usePhrases';
import moment from 'moment';
import useDateTimeFormat from '@frontend/utils/useDateTimeFormat';
import _ from 'lodash';

const emptyScrapType = {
  identifier: '',
  description: '',
  value: 0,
  note: '',
  startTime: null,
  endTime: null,
};

const useEditQualityReportForm = (callback, selectedReport, modalOpen, allQualityReports, machine, selectedState, actualCount) => {
  const api = useApi();
  const phrases = usePhrases()
    .phrases();
  const { createAlert } = useAlert();
  const { validateQualityReportForm } = useValidateQualityReportForm(actualCount);
  const [qualityReportValues, setQualityReportValues] = useState(emptyScrapType);
  const [oldQualityReportValues, setOldQualityReportValues] = useState(emptyScrapType);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loadingScrapTypes, setLoadingScrapTypes] = useState(false);
  const [isLoadingNote, setIsLoadingNote] = useState(false);
  const [scrapTypesByCategory, setScrapTypesByCategory] = useState([]);
  const [warning, setWarning] = useState('');
  const [qualityReportsForMachine, setQualityReportsForMachine] = useState(allQualityReports);
  const timespanStart = useSelector((state) => state.timespanStart);
  const timespanEnd = useSelector((state) => state.timespanEnd);
  const { formatDate } = useDateTimeFormat();
  // let cancelAxios = null;
  function getNote(noteId) {
    if (noteId) {
      if (!isLoadingNote) setIsLoadingNote(true);
      api(`/api/shared/notes/${noteId}`, {
        method: 'get',
      })
        .then((response) => {
          if (response) {
            setQualityReportValues({
              ...qualityReportValues,
              note: response.data.text,
            });
            setOldQualityReportValues({
              ...oldQualityReportValues,
              note: response.data.text,
            });
            setIsLoadingNote(false);
          }
        })
        .catch((error) => {
          const errorMessage = phrases.forms.structure.errors.genericError;
          setIsLoadingNote(false);
          if (error.response) {
            if (error.response.status === 404) {
              createAlert(errorMessage, 'error');
              setIsSubmitting(false);
            } else if (error.response.status === 403) {
              setIsSubmitting(false);
              createAlert(phrases.errorMessages.notAuthorized, 'error');
            } else {
              createAlert(errorMessage, 'error');
              setIsSubmitting(false);
            }
          }
        });
    } else {
      setQualityReportValues({
        ...qualityReportValues,
        note: '',
        noteId: '',
      });
    }
  }

  useEffect(() => {
    const cancelAxios = axios.CancelToken.source();
    return () => {
      if (cancelAxios) cancelAxios.cancel('Component unmounted');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    } else {
      setIsSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    if (!_.isEmpty(selectedReport) && !_.isEmpty(selectedReport.report)) {
      const scrapType = selectedReport.report?.['quality.scrapType'];
      setQualityReportValues({
        ...qualityReportValues,
        identifier: scrapType,
        value: selectedReport.report[`quality.${scrapType}.wasteCount`],
        noteId: selectedReport.report[`quality.${scrapType}.note`],
        startTime: selectedReport.report[`quality.${scrapType}.startTime`],
        endTime: selectedReport.report[`quality.${scrapType}.endTime`],
      });
      setOldQualityReportValues({
        ...qualityReportValues,
        identifier: scrapType,
        value: selectedReport.report[`quality.${scrapType}.wasteCount`],
        noteId: selectedReport.report[`quality.${scrapType}.note`],
        startTime: selectedReport.report[`quality.${scrapType}.startTime`],
        endTime: selectedReport.report[`quality.${scrapType}.endTime`],
      });
      setQualityReportsForMachine(allQualityReports.filter((_qualityReport) => {
        return !(_qualityReport[`quality.${scrapType}.wasteCount`] === selectedReport.report[`quality.${scrapType}.wasteCount`]
          && _qualityReport[`quality.${scrapType}.note`] === selectedReport.report[`quality.${scrapType}.note`]
          && _qualityReport[`quality.${scrapType}.startTime`] === selectedReport.report[`quality.${scrapType}.startTime`]
          && _qualityReport[`quality.${scrapType}.endTime`] === selectedReport.report[`quality.${scrapType}.endTime`]);
      }));
    } 
    else if(!_.isEmpty(selectedReport)) {
      setQualityReportValues({
        ...selectedReport,
      });
      setOldQualityReportValues(null);
      setQualityReportsForMachine(allQualityReports);
    }
    else {
      setQualityReportValues({
        ...qualityReportValues,
        startTime: selectedState ? moment(selectedState.startTime) : moment(timespanEnd).subtract(1, 'hour').subtract(1, 'minute'),
        endTime: selectedState ? moment(selectedState.endTime) : moment(timespanEnd).subtract(1, 'minute'),
      });
      setOldQualityReportValues(null);
      setQualityReportsForMachine(allQualityReports);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReport, allQualityReports]);

  useEffect(() => {
    if (modalOpen) {
      setLoadingScrapTypes(true);
      api(`/api/quality/scrap-types?mongoQuery={"$or": [{"machineTypes": {"$in": ["${machine?.type?.id}"]}},{"machineTypes": {"$size": 0}}], "enabled": true }`, {
        method: 'get',
      })
        .then((response) => {
          setScrapTypesByCategory(response.data);
          setLoadingScrapTypes(false);
        })
        .catch((error) => {
          if (!axios.isCancel(error)) console.log(error);
          setLoadingScrapTypes(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen]);

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    setIsSubmitting(true);
    setErrors({ ...validateQualityReportForm(qualityReportValues) });
  };

  const findOverlapReport = (startTime, endTime, _scrapType) => {
    return qualityReportsForMachine.find((_report) => {
      const scrapType = _report['quality.scrapType'];
      const currentScrapType = _scrapType || qualityReportValues.identifier;
      const reportStartTime = moment(_report[`quality.${scrapType}.startTime`]);
      const reportEndTime = moment(_report[`quality.${scrapType}.endTime`]);

      const firstOverlapCheck = reportStartTime.isBetween(moment(startTime), moment(endTime), null, '[]')
        || reportEndTime.isBetween(moment(startTime), moment(endTime), null, '[]');
      const secondOverlapCheck = moment(startTime).isBetween(moment(reportStartTime), moment(reportEndTime), null, '[]')
        || moment(endTime).isBetween(moment(reportStartTime), moment(reportEndTime), null, '[]');
      if (scrapType === currentScrapType && (firstOverlapCheck || secondOverlapCheck)) return true;

      return false;
    });
  };

  const validateDates = (startTime, endTime, _scrapType) => {
    // const isWithinTimeframe = moment(startTime).isBetween(moment(timespanStart), moment(timespanEnd), 'minutes', [])
    //   && moment(endTime).isBetween(moment(timespanStart), moment(timespanEnd), null, '[]');
    const overlapReport = findOverlapReport(startTime, endTime, _scrapType);
    // if (!isWithinTimeframe) {
    //   setErrors({
    //     timespan: 'Cant be outside selected timeframe.',
    //   });
    //   return false;
    // }

    if (overlapReport) {
      const scrapType = overlapReport['quality.scrapType'];
      setErrors({
        timespan: `There is another quality report from ${formatDate(overlapReport[`quality.${scrapType}.startTime`])} to ${formatDate(overlapReport[`quality.${scrapType}.endTime`])}`,
      });
      return false;
    }

    if (errors.timespan) delete errors.timespan;

    return true;
  };

  const handleChangeScrapType = (scrapType) => {
    setQualityReportValues({
      note: qualityReportValues.note,
      noteId: qualityReportValues.noteId,
      value: qualityReportValues.value,
      startTime: qualityReportValues.startTime,
      endTime: qualityReportValues.endTime,
      ...scrapType,
    });
    validateDates(qualityReportValues.startTime, qualityReportValues.endTime, scrapType.identifier);
  };

  const handleChangeNote = (event) => {
    event.persist();
    if (errors[event.target.name]) {
      delete errors[event.target.name];
    }
    setQualityReportValues({
      ...qualityReportValues,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeValue = (inputValue) => {
    setQualityReportValues({
      ...qualityReportValues,
      value: Number(inputValue),
    });
  };

  const handleStartTimeChange = (inputValue) => {
    if (validateDates(inputValue, qualityReportValues.endTime)) {
      setQualityReportValues({
        ...qualityReportValues,
        startTime: inputValue,
      });
    }
  };

  useEffect(() => {
    validateDates(qualityReportValues.startTime, qualityReportValues.endTime, qualityReportValues.identifier);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qualityReportValues.startTime, qualityReportValues.endTime, qualityReportValues.identifier]);

  const handleEndTimeChange = (inputValue) => {
    if (validateDates(qualityReportValues.startTime, inputValue)) {
      setQualityReportValues({
        ...qualityReportValues,
        endTime: inputValue,
      });
    }
  };

  const handleClearForm = () => {
    setQualityReportValues({
      ...emptyScrapType,
      startTime: selectedState ? moment(selectedState.startTime) : moment(timespanStart).add(1, 'minute'),
      endTime: selectedState ? moment(selectedState.endTime) : moment(timespanEnd).subtract(1, 'minute'),
    });
    setErrors({});
  };

  useEffect(() => {
    if (qualityReportValues.noteId) getNote(qualityReportValues.noteId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qualityReportValues.noteId]);

  const setScrapTypeForEdit = (selectedState, scrapType) => {
    setQualityReportValues({
      ...qualityReportValues,
      identifier: scrapType,
      value: selectedState.state[`presentation.quality.${scrapType}.wasteCount`],
      noteId: selectedState.state[`presentation.quality.${scrapType}.note`],
      startTime: selectedState.state[`presentation.quality.${scrapType}.startTime`],
      endTime: selectedState.state[`presentation.quality.${scrapType}.endTime`],
    });
    if (warning) setWarning('');
  };

  return {
    handleStartTimeChange,
    handleEndTimeChange,
    handleChangeScrapType,
    handleChangeValue,
    handleChangeNote,
    handleSubmit,
    handleClearForm,
    qualityReportValues,
    oldQualityReportValues,
    setScrapTypeForEdit,
    scrapTypesByCategory,
    errors,
    isSubmitting,
    isLoadingNote,
    loadingScrapTypes,
    setIsSubmitting,
    warning,
    setWarning,
  };
};

export default useEditQualityReportForm;
