import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from '@material-ui/core';

import { usePhrases } from '@frontend/utils/usePhrases';
import MuiNumpad                        from '@frontend/modules/quality/components/MUI-Numpad';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    loadingBtnIcon: {
      marginLeft: theme.spacing(1),
    },
  }));
  
const NumpadModal = (props) => {
    const {
        isOpen,
        handleClose,
        handleCallback,
        modalTitle
    } = props;

    const classes = useStyles();
    const phrases = usePhrases().phrases();
    const [currentValue, setCurrentValue] = useState(0);
    
    const submitValue = () => {
        handleCallback(currentValue);
        handleClose();
    }

    return ( 
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          square: true,
        }}
      >
        <DialogTitle id="alert-dialog-title">{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <TextField
                id="count-field"
                name="count"
                variant="outlined"
                type="number"
                fullWidth
                value={currentValue}
                margin="dense"
              />
            <MuiNumpad
                onChange={setCurrentValue}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="text" color="default">
            {phrases.confirmationModal.cancel}
          </Button>
          <Button onClick={submitValue} variant="contained" color="primary" autoFocus>
            {phrases.confirmationModal.confirm}            
          </Button>
        </DialogActions>
      </Dialog>
    </div> );
}
 
export default NumpadModal;