import { useEffect, useState } from 'react';
import moment from 'moment';
import useApi from '@frontend/utils/useApi';


const useReviewFlowPulse = (machine, timeframeStart, timeframeEnd) => {
    const [loading, setLoading] = useState(false);
    const [machineStates, setMachineStates] = useState({});
    const api = useApi();

    // Get the machineStates in timeframe
    const reloadStates = () => {
        setLoading(true);
        try {
            api(`/api/machines/states`, {
            method: 'post',
            data: {
                machines: [machine.id],
                from: moment(timeframeStart),
                to: moment(timeframeEnd),
                overview: false,
            },            
            })
            .then((response) => {
                if(response.status === 200) {
                    setMachineStates(response.data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    }

    useEffect(() => {
        reloadStates();
    }, [timeframeStart, timeframeEnd])

    return {
        loading,
        machineStates,
        reloadStates
    };
}
 
export default useReviewFlowPulse;