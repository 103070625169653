/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  DialogActions,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { makeStyles }     from '@material-ui/core/styles';
import DateRangeIcon      from '@material-ui/icons/DateRange';
import {
  MuiPickersUtilsProvider,
  DateTimePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';

import {
  setIsRelativeTimespan,
  setSelectedTimespanText,
  setIsCustomPreset,
  setSelectedRelativeTimespan,
} from '@frontend/utils/UIActions';
import useDateTimeFormat    from '@frontend/utils/useDateTimeFormat';
import { usePhrases }       from '@frontend/utils/usePhrases';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  speedDial: {
    position: 'fixed',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionRight': {
      bottom: theme.spacing(2),
      left: theme.spacing(0),
    },
    alignItems: 'start',
  },
  speedDialChip: {
    position: 'fixed',
    bottom: theme.spacing(3.3),
    left: theme.spacing(9),
    padding: theme.spacing(1),
    fontWeight: 'bold',
    maxWidth: '60%',
  },
  restoreChipIcon: {
    height: 'auto',
  },
  dateSelection: {
    marginLeft: theme.spacing(1),
  },
  inputField: {
    width: '100%',
  },
  selectField: {
    width: '100%',
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(0.5),
  },
  dialogContent: {
    padding: 0,
    margin: 0,
  },
  modal: {
    width: '100%',
  },
  presetList: {
    width: '100%',
  },
  speedDialIcon: {
    marginRight: theme.spacing(1),
  },
  backDrop: {
    zIndex: 10,
  },
  listTitle: {
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    fontWeight: 'bold',
  },
  selectedPreset: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
}));

const TimespanDateRangeSelection = (props) => {
  const {
    onTimespanSelect,
    selectTimeframe,
    handleCloseDialog,
  } = props;


  // ===== Redux Selectors =====
  const machines = useSelector((state) => state.machines);
  const timespanStart = useSelector((state) => state.timespanStart);
  const timespanEnd = useSelector((state) => state.timespanEnd);

  // ===== State Objects =====
  const [startDate, handleStartDateChange] = useState(moment().subtract(12, 'hours').toDate());
  const [endDate, setEndDate] = useState(new Date());

  // ===== Imports =====
  const classes = useStyles();
  const dispatch = useDispatch();
  const phrases = usePhrases().phrases();
  const { formatDate, getLocale } = useDateTimeFormat();

  // ===== Effects =====
  useEffect(() => {
    if (timespanStart && timespanEnd) {
      handleStartDateChange(timespanStart);
      setEndDate(timespanEnd);
    }
  }, [timespanStart, timespanEnd]);

  // ===== Helper Methods =====
  const updatePulse = () => {
    dispatch(setIsCustomPreset(false));
    dispatch(setSelectedTimespanText(`${formatDate(startDate)} - ${formatDate(endDate)}`));
    dispatch(setIsRelativeTimespan(false));
    dispatch(setSelectedRelativeTimespan({ amount: 0, unit: '' }));
    if (selectTimeframe) selectTimeframe(machines, startDate, endDate);
    if (onTimespanSelect) { onTimespanSelect(startDate, endDate, undefined, undefined, false); }
    handleCloseDialog();
  };

  const handleEndDateChange = (date) => {
    if(moment(date).isAfter(moment())){
      setEndDate(moment().toDate());
    } else {
      setEndDate(date);
    };
  }
  const handlePresetClick = (start, end) => {
    handleStartDateChange(start);
    setEndDate(end);
  };

  const selectedDateRangeIsEqual = (start, end) => moment(start).isSame(startDate, 'minute') && moment(end).isSame(endDate, 'minute');

  return (
    <div>
      <List>
        <ListItem>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale()}>
            <DateTimePicker
              autoOk
              ampm={false}
              disableFuture
              inputVariant="outlined"
              format={phrases.timespanSelection.timeFormat}
              margin="dense"
              minutesStep={1}
              maxDate={endDate}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <DateRangeIcon />
                  </InputAdornment>
                ),
              }}
              value={startDate}
              onChange={handleStartDateChange}
              label={phrases.timespanSelection.from}
            />
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale()}>
            <DateTimePicker
              autoOk
              ampm={false}
              maxDate={new Date()}
              disableFuture={true}
              inputVariant="outlined"
              margin="dense"
              format={phrases.timespanSelection.timeFormat}
              minutesStep={1}
              minDate={startDate}
              className={classes.dateSelection}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <DateRangeIcon />
                  </InputAdornment>
                ),
              }}
              value={endDate}
              onChange={handleEndDateChange}
              label={phrases.timespanSelection.to}
            />
          </MuiPickersUtilsProvider>

        </ListItem>
        <ListItem>
          <List className={classes.presetList}>
            <div className={classes.listTitle}>{phrases.timespanSelection.Presets}</div>

            <ListItem button onClick={() => handlePresetClick(moment().startOf('day').toDate(), moment().toDate())}>
              <ListItemText classes={selectedDateRangeIsEqual(moment().startOf('day').toDate(), moment(endDate).toDate()) ? { primary: classes.selectedPreset } : {}} primary={phrases.timespanSelection.Today} secondary={phrases.timespanSelection.ShowPulseForToday} />
            </ListItem>

            <ListItem button onClick={() => handlePresetClick(moment().subtract(1, 'days').startOf('day').toDate(), moment().subtract(1, 'days').endOf('day').toDate())}>
              <ListItemText classes={selectedDateRangeIsEqual(moment().subtract(1, 'days').startOf('day').toDate(), moment().subtract(1, 'days').endOf('day').toDate()) ? { primary: classes.selectedPreset } : {}} primary={phrases.timespanSelection.Yesterday} secondary={phrases.timespanSelection.ShowPulseForYesterday} />
            </ListItem>

            <ListItem button onClick={() => handlePresetClick(moment().startOf('isoWeek').toDate(), moment().toDate())}>
              <ListItemText classes={selectedDateRangeIsEqual(moment().startOf('isoWeek').toDate(), moment(endDate).toDate()) ? { primary: classes.selectedPreset } : {}} primary={phrases.timespanSelection.ThisWeek} secondary={phrases.timespanSelection.ShowPulseForThisWeek} />
            </ListItem>

            <ListItem button onClick={() => handlePresetClick(moment().subtract(1, 'weeks').startOf('isoWeek').toDate(), moment().subtract(1, 'weeks').endOf('isoWeek').toDate())}>
              <ListItemText classes={selectedDateRangeIsEqual(moment().subtract(1, 'weeks').startOf('isoWeek').toDate(), moment().subtract(1, 'weeks').endOf('isoWeek').toDate()) ? { primary: classes.selectedPreset } : {}} primary={phrases.timespanSelection.LastWeek} secondary={phrases.timespanSelection.ShowPulseForLastWeek} />
            </ListItem>

            <ListItem button onClick={() => handlePresetClick(moment().startOf('month').toDate(), moment().toDate())}>
              <ListItemText classes={selectedDateRangeIsEqual(moment().startOf('month').toDate(), moment().toDate()) ? { primary: classes.selectedPreset } : {}} primary={phrases.timespanSelection.ThisMonth} secondary={phrases.timespanSelection.ShowPulseForThisMonth} />
            </ListItem>
            
            <ListItem button onClick={() => handlePresetClick(moment().startOf('year').toDate(), moment().toDate())}>
              <ListItemText classes={selectedDateRangeIsEqual(moment().startOf('year').toDate(), moment().toDate()) ? { primary: classes.selectedPreset } : {}} primary={phrases.timespanSelection.ThisYear} secondary={phrases.timespanSelection.ShowPulseForThisYear} />
            </ListItem>
          </List>
        </ListItem>
      </List>
      <DialogActions>
        <Button onClick={handleCloseDialog} variant="contained" color="default">
          {phrases.forms.shared.buttons.cancel}
        </Button>
        <Button onClick={updatePulse} variant="contained" color="primary">
          {phrases.forms.shared.buttons.updatePulse}
        </Button>
      </DialogActions>
    </div>
  );
};

export default TimespanDateRangeSelection;
