import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useApi from '@frontend/utils/useApi';
import moment from 'moment';

const useJobSpeed = (machineToEngage) => {
    const api = useApi();
    const [currentSpeed, setCurrentSpeed] = useState(0);  
    const [avgSpeed, setAvgSpeed] = useState(0);  

    const timespanStart = useSelector((state) => state.timespanStart);
    const timespanEnd = useSelector((state) => state.timespanEnd);

    useEffect(() => {
        if (machineToEngage.id) {
          api(`/api/performance/parts-detailed?from=${moment(timespanStart)}&to=${moment(timespanEnd)}&machine=${machineToEngage.id}`, {
            method: 'get',
          }).then((response) => {
            setAvgSpeed(response.data.avgSpeed ?? 0);         
            setCurrentSpeed(response.data.currentSpeed ?? 0);     
          }).catch((error) => {
          });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [timespanStart, timespanEnd, machineToEngage]);

    return {
        currentSpeed,
        avgSpeed
    };
}
 
export default useJobSpeed;