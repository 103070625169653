/* eslint-disable react/display-name */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import MUIDataTable from 'mui-datatables';
import {
  createMuiTheme, MuiThemeProvider, makeStyles,
} from '@material-ui/core/styles';
import {
  Grid,
  TextField,
  InputAdornment,
  useTheme,
  Button,
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import { Pagination }     from '@material-ui/lab';

import useMachineKPIs     from '@frontend/hooks/useMachineKPIs';
import useMachineStats    from '@frontend/hooks/useMachineStats';
import loader             from '@frontend/loader/loader';
import useMachineFilters  from '@frontend/modules/machine/hooks/useMachineFilters';
import MachineCollapse    from '@frontend/modules/machine/MachineCollapse';
import ToolbarSort        from '@frontend/modules/machine/ToolbarSort';
import ToolbarSearch      from '@frontend/modules/machine/ToolbarSearch';
import useDateTimeFormat  from '@frontend/utils/useDateTimeFormat';
import { usePhrases }     from '@frontend/utils/usePhrases';
import useConditionalArrayElement     from '@frontend/utils/useConditionalArrayElement';
import { setProductionPulseFilters }  from '@frontend/utils/UIActions';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: 'relative',
  },
  machinePulse: {
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(0),
    width: '100%',
    textAlign: 'left',
  },
  pagination: {
    marginTop: theme.spacing(2),
  },  
}));

const MachinePulses = React.memo(({
  structure, machines, sensors, fetchNextBatch, pageCount, refreshPulse, setMachineStates,
}) => {
  const classes = useStyles();
  const phrases = usePhrases().phrases();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { formatDate, formatHoursMinutesDuration } = useDateTimeFormat();
  const { moduleLoaded } = loader();
  const { conditionalElement } = useConditionalArrayElement();
  const {
    applyFilters,
    applySearch,
    applySort,
  } = useMachineFilters();

  const [tempFilterList, setTempFilterList] = useState([]);

  const filterList = useSelector((state) => state.productionPulseFilters.filterList);
  const searchQuery = useSelector((state) => state.productionPulseFilters.searchQuery);
  const sortType = useSelector((state) => state.productionPulseFilters.sortType);

  const machineStates = useSelector((state) => state.machineStates);
  const timespanStart = useSelector((state) => state.timespanStart);
  const timespanEnd = useSelector((state) => state.timespanEnd);
  const isRelativeTimespan = useSelector((state) => state.isRelativeTimespan);

  const [currentPage, setCurrentPage] = useState(1);
  const {
    getMachineAvailability,
    getMachinePerformance,
    getMachineQuality,
  } = useMachineKPIs();

  const {
    getMachineDowntimePercentage,
    getMachineRuntimePercentage,
    getMachineRunning,
  } = useMachineStats();

  // Decorator for the current Page update
  const refreshPulseOnPage = () => {
    refreshPulse(currentPage);
  }
  
  // TODO: Refactor for responsiveness
  const columns = [
    {
      label: phrases.forms.shared.fields.name,
      name: 'name',
      options: {
        filter: false,
        filterList: filterList[0],
        customBodyRender: (value, tableMeta, updateValue) => (
          <div className={classes.machinePulse}>
            <MachineCollapse
              key={`${machines[[tableMeta.rowIndex]].id}-col`}
              machine={machines[[tableMeta.rowIndex]]}
              structure={structure}
              sensors={sensors}
              refreshPulse={refreshPulseOnPage}
              overview={true} />
          </div>
        ),
      },
    },
    {
      label: phrases.forms.shared.fields.structure,
      name: 'structure',
      options: {
        filter: true,
        filterType: 'multiselect',
        filterList: filterList[1],
        display: 'excluded',
        filterOptions: {
          names: _.uniq(_.map(structure.machines, 'structure.path')),
          logic: (structure, filters, row) => false,
        },
        customBodyRender: (value, tableMeta, updateValue) => (
          <>{value.name}</>
        ),
      },
    },
    {
      label: phrases.forms.shared.fields.type,
      name: 'type',
      options: {
        filter: true,
        display: 'excluded',
        download: false,
        filterType: 'multiselect',
        filterList: filterList[2],
        filterOptions: {
          names: _.uniq(_.map(structure.machines, 'type.name')),
          logic: (type, filters, row) => false,
        },
        customBodyRender: (value, tableMeta, updateValue) => (
          <>{value.name}</>
        ),
      },
    },
    {
      label: phrases.modules.machine.runtime,
      name: 'runtime',
      options: {
        filter: false,
        display: 'excluded',
        customBodyRender: (value, tableMeta, updateValue) => (
          formatHoursMinutesDuration(getMachineRuntimePercentage(machines[[tableMeta.rowIndex]], machineStates))
        ),
      },
    },
    {
      label: phrases.modules.machine.downtime,
      name: 'downtime',
      options: {
        filter: false,
        display: 'excluded',
        customBodyRender: (value, tableMeta, updateValue) => (
          formatHoursMinutesDuration(getMachineDowntimePercentage(machines[[tableMeta.rowIndex]], machineStates))
        ),
      },
    },
    {
      label: phrases.misc.availability,
      name: 'minAvailability',
      options: {
        display: 'excluded',
        filter: true,
        filterType: 'custom',
        filterList: filterList[5],
        download: true,
        customFilterListOptions: { render: (v) => `${phrases.misc.minAvailability}: ${v}%` },
        filterOptions: {
          logic: (availability, filters) => false,
          display: (_filterList, onChange, index, column) => (
            <TextField
              variant="outlined"
              margin="dense"
              value={_filterList[index][0] || ''}
              id="standard-adornment-weight"
              label={phrases.misc.minAvailability}
              onChange={(event) => {
                _filterList[index][0] = event.target.value;
                onChange(_filterList[index], index, column);
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                startAdornment: <InputAdornment position="start"><AccessTimeIcon /></InputAdornment>,
              }}
              aria-describedby="standard-weight-helper-text"
            />
          ),
        },
        customBodyRender: (value, tableMeta, updateValue) => (
          `${getMachineAvailability(machines[[tableMeta.rowIndex]], machineStates)}%`
        ),
      },
    },
    {
      label: phrases.misc.availability,
      name: 'maxAvailability',
      options: {
        display: 'excluded',
        filter: true,
        download: false,
        customFilterListOptions: { render: (v) => `${phrases.misc.maxAvailability}: ${v}%` },
        filterType: 'custom',
        filterList: filterList[6],
        filterOptions: {
          logic: (availability, filters) => false,
          display: (_filterList, onChange, index, column) => (
            <TextField
              variant="outlined"
              margin="dense"
              id="standard-adornment-weight"
              value={_filterList[index][0] || ''}
              label={phrases.misc.maxAvailability}
              onChange={(event) => {
                _filterList[index][0] = event.target.value;
                onChange(_filterList[index], index, column);
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                startAdornment: <InputAdornment position="start"><AccessTimeIcon /></InputAdornment>,
              }}
              aria-describedby="standard-weight-helper-text"
            />
          ),
        },
        customBodyRender: (value, tableMeta, updateValue) => (
          `${getMachineAvailability(machines[[tableMeta.rowIndex]], machineStates)}%`
        ),
      },
    },
    // Performance
    ...conditionalElement(moduleLoaded('performance'), {
      label: phrases.modules.performance.performance,
      name: 'minPerformance',
      options: {
        display: 'excluded',
        filter: true,
        filterType: 'custom',
        filterList: filterList[7],
        download: true,
        customFilterListOptions: { render: (v) => `${phrases.modules.performance.minPerformance}: ${v}%` },
        filterOptions: {
          logic: (performance, filters) => false,
          display: (_filterList, onChange, index, column) => (
            <TextField
              variant="outlined"
              margin="dense"
              value={_filterList[index][0] || ''}
              id="standard-adornment-weight"
              label={phrases.modules.performance.minPerformance}
              onChange={(event) => {
                _filterList[index][0] = event.target.value;
                onChange(_filterList[index], index, column);
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                startAdornment: <InputAdornment position="start"><AccessTimeIcon /></InputAdornment>,
              }}
              aria-describedby="standard-weight-helper-text"
            />
          ),
        },
        customBodyRender: (value, tableMeta, updateValue) => (
          `${getMachinePerformance(machines[[tableMeta.rowIndex]], machineStates)}%`
        ),
      },
    }),
    ...conditionalElement(moduleLoaded('performance'), {
      label: phrases.modules.performance.performance,
      name: 'maxPerformance',
      options: {
        display: 'excluded',
        filter: true,
        filterType: 'custom',
        filterList: filterList[8],
        download: false,
        customFilterListOptions: { render: (v) => `${phrases.modules.performance.maxPerformance}: ${v}%` },
        filterOptions: {
          logic: (performance, filters) => false,
          display: (_filterList, onChange, index, column) => (
            <TextField
              variant="outlined"
              margin="dense"
              value={_filterList[index][0] || ''}
              id="standard-adornment-weight"
              label={phrases.modules.performance.maxPerformance}
              onChange={(event) => {
                _filterList[index][0] = event.target.value;
                onChange(_filterList[index], index, column);
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                startAdornment: <InputAdornment position="start"><AccessTimeIcon /></InputAdornment>,
              }}
              aria-describedby="standard-weight-helper-text"
            />
          ),
        },
        customBodyRender: (value, tableMeta, updateValue) => (
          `${getMachinePerformance(machines[[tableMeta.rowIndex]], machineStates)}%`
        ),
      },
    }),
    // Quality
    ...conditionalElement(moduleLoaded('quality'), {
      label: phrases.modules.quality.quality,
      name: 'minQuality',
      options: {
        display: 'excluded',
        filter: true,
        filterType: 'custom',
        filterList: filterList[9],
        download: true,
        customFilterListOptions: { render: (v) => `${phrases.modules.quality.minQuality}: ${v}%` },
        filterOptions: {
          logic: (performance, filters) => false,
          display: (_filterList, onChange, index, column) => (
            <TextField
              variant="outlined"
              margin="dense"
              value={_filterList[index][0] || ''}
              id="standard-adornment-weight"
              label={phrases.modules.quality.minQuality}
              onChange={(event) => {
                _filterList[index][0] = event.target.value;
                onChange(_filterList[index], index, column);
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                startAdornment: <InputAdornment position="start"><AccessTimeIcon /></InputAdornment>,
              }}
              aria-describedby="standard-weight-helper-text"
            />
          ),
        },
        customBodyRender: (value, tableMeta, updateValue) => (
          `${getMachineQuality(machines[[tableMeta.rowIndex]], machineStates)}%`
        ),
      },
    }),
    ...conditionalElement(moduleLoaded('quality'), {
      label: phrases.modules.quality.quality,
      name: 'maxQuality',
      options: {
        display: 'excluded',
        filter: true,
        filterType: 'custom',
        filterList: filterList[10],
        download: false,
        customFilterListOptions: { render: (v) => `${phrases.modules.quality.maxQuality}: ${v} % ` },
        filterOptions: {
          logic: (performance, filters) => false,
          display: (_filterList, onChange, index, column) => (
            <TextField
              variant="outlined"
              margin="dense"
              value={_filterList[index][0] || ''}
              id="standard-adornment-weight"
              label={phrases.modules.quality.maxQuality}
              onChange={(event) => {
                _filterList[index][0] = event.target.value;
                onChange(_filterList[index], index, column);
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                startAdornment: <InputAdornment position="start"><AccessTimeIcon /></InputAdornment>,
              }}
              aria-describedby="standard-weight-helper-text"
            />
          ),
        },
        customBodyRender: (value, tableMeta, updateValue) => (
          `${getMachineQuality(machines[[tableMeta.rowIndex]], machineStates)}% `
        ),
      },
    }),
    (isRelativeTimespan ? {
      label: phrases.modules.machine.running,
      name: 'running',
      options: {
        filter: true,
        filterType: 'checkbox',
        display: 'excluded',
        filterList: filterList[11],
        download: false,
        filterOptions: {
          names: [phrases.modules.machine.running, phrases.modules.machine.notRunning],
          logic: (running, filters) => false,
        },
        customBodyRender: (value, tableMeta, updateValue) => (
          `${getMachineRunning(machines[[tableMeta.rowIndex]], machineStates)} `
        ),
      },

    }
      : {
        label: phrases.modules.machine.running,
        name: 'running',
        download: false,
        options: {
          filter: false,
        },
      }),
  ];

  const getMuiTheme = () => createMuiTheme({
    ...theme,
    overrides: {
      MUIDataTableBodyCell: {
        root: {
          padding: 0,
          '&:lastChild': {
            borderBottom: 'none',
          },
        },
      },
      MUIDataTableHeadCell: {
        root: {
          display: 'none',
        },
      },
      MuiGridListTile: {
        root: {
          [theme.breakpoints.down('sm')]: {
            width: '50% !important',
          },
        },
      },
      MUIDataTableFilter: {

        checkboxFormControlLabel: {
          marginLeft: 0,
          marginRight: theme.spacing(1),
        },
        root: {
          // backgroundColor: 'pink',
          // width: '100%',

        },

      },
      MuiPopover: {
        root: {
          // backgroundColor: 'red',

        },
        paper: {
          [theme.breakpoints.down('sm')]: {
            position: 'relative',
            maxWidth: '95% !important',
            left: '2.5% !important',
            top: '2.5% !important',
          },
        },
      },

    },
  });

  const processMachines = (_searchQuery, _filterList, _sortType) => {
    const allMachines = structure.machines;
    const sortedMachines = applySort(_sortType, allMachines);
    const searchedMachines = _searchQuery ? applySearch(_searchQuery, sortedMachines) : sortedMachines;
    const filteredMachines = _filterList ? applyFilters(_filterList, searchedMachines) : searchedMachines;
    setMachineStates(filteredMachines);
  };

  const handleChangeSortType = (sortType) => {
    dispatch(setProductionPulseFilters({
      searchQuery: searchQuery,
      filterList: filterList,
      sortType
    }));
    processMachines(searchQuery, filterList, sortType);
  }

  const handlePageChange = (e, value) => {
    setCurrentPage(value);
    fetchNextBatch(value);
  }

  const options = {
    textLabels: phrases.tables.textLabels,
    filterType: 'checkbox',
    print: false,
    download: false,
    elevation: 0,
    selectableRows: 'none',
    responsive: 'scrollFullHeight',
    viewColumns: false,
    rowHover: false,
    pagination: false,
    searchOpen: !!searchQuery,
    searchText: searchQuery,
    downloadOptions: {
      filename: `${structure.path} - ${formatDate(timespanStart)} - ${formatDate(timespanEnd)}.csv`,
      separator: ',',
      filterOptions: {
        useDisplayedRowsOnly: true,
      },
    },
    onFilterChange: (changedColumn, _filterList, type) => {
      const changedColumnIndex = columns.findIndex((col) => col.name === changedColumn.name);
      setTempFilterList({
        ...tempFilterList,
        [changedColumn.name]: _filterList[changedColumnIndex],
      });
      // Trigger setFilter list on last column
      // TODO: Fix so that it is not hardcoded
      if (changedColumn.name === 'running') dispatch(setProductionPulseFilters({
        searchQuery: searchQuery,
        filterList: _filterList,
        sortType: sortType
      }));
      if (type === 'chip' || type === 'custom') dispatch(setProductionPulseFilters({
        searchQuery: searchQuery,
        filterList: _filterList,
        sortType: sortType
      }));
      processMachines(searchQuery, _filterList, sortType);
    },
    onSearchClose: () => {
      dispatch(setProductionPulseFilters({
        searchQuery: '',
        filterList: filterList,
        sortType: sortType
      }));
      processMachines('', filterList, sortType);
    },
    searchProps: {
      onSearch: (searchValue) => {
        dispatch(setProductionPulseFilters({
          searchQuery: searchValue,
          filterList: filterList,
          sortType: sortType
        }));
        processMachines(searchValue, filterList, sortType);
      },
    },
    customSearch: (searchQuery, currentRow, columns) => true,
    confirmFilters: true,
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => (
      <div style={{ marginTop: '40px' }}>
        <Button variant="contained" onClick={()=>{
          dispatch(setProductionPulseFilters({
            searchQuery: searchQuery,
            filterList: currentFilterList,
            sortType: sortType
          }));
          processMachines(searchQuery, currentFilterList, sortType);
          return applyNewFilters()}}>{phrases.misc.applyFilters}</Button>
      </div>
    ),
    customToolbar: () => (
      <ToolbarSort applySort={handleChangeSortType} selectedSort={sortType} />
    ),
    customSearchRender: (searchText, handleSearch, hideSearch, options) => <ToolbarSearch value={searchQuery} handleSearch={options.searchProps.onSearch} hideSearch={hideSearch} />,
  };


  return (
    <div className={classes.root}>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={""}
          data={machines}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
      <Grid container item xs={12} justify="center" className={classes.pagination}>
        <Pagination variant="outlined" color="primary" count={pageCount} page={currentPage} onChange={handlePageChange} />
      </Grid>      
    </div>
  );
});

MachinePulses.propTypes = {
  structure: PropTypes.instanceOf(Object).isRequired,
  machines: PropTypes.instanceOf(Array).isRequired,
  sensors: PropTypes.instanceOf(Array).isRequired,
};

export default MachinePulses;
