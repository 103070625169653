/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable eqeqeq */
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';

import useQuery           from '@frontend/hooks/useQuery';
import useReloadTimes     from '@frontend/hooks/useReloadTimes';
import useDateTimeFormat  from '@frontend/utils/useDateTimeFormat';
import { usePhrases }     from '@frontend/utils/usePhrases';
import useApi             from '@frontend/utils/useApi';
import useSecurity        from '@frontend/utils/useSecurity';
import socket             from '@frontend/utils/useSocket';
import {
  setLastPulseUpdate,
  setTimespanStart,
  setTimespanEnd,
  setTimespanDuration,
  setIsRelativeTimespan,
  setLastReloadTime,
  setSelectedTimespanText,
  setSelectedRelativeTimespan,
} from '@frontend/utils/UIActions';
import { 
  storeListOfMachines,
  storeMachineStates 
} from '@frontend/modules/machine/actions';

let CancelToken = axios.CancelToken;
let cancelSensors;
let cancelMachines;

const useMachineWithStates = () => {
  const { getProfile } = useSecurity();
  // ===== State Objects =====
  // Sensors from all machines
  const [sensors, setSensors] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoadingMachines, setIsLoadingMachines] = useState(false);
  const [allMachines, setAllMachines] = useState([]);
  const [pageCount, setPageCount] = useState([]);
  const [thisTimeUpdated, setThisTimeUpdated] = useState();
  const [lazyLoadBatch, setLazyLoadBatch] = useState(getProfile().views?.machinesPerPage || 10);
  // ===== Redux Selectors =====
  const dataLayer = useSelector((state) => state.dataLayer);
  const isRelativeTimespan = useSelector((state) => state.isRelativeTimespan);
  const selectedRelativeTimespan = useSelector((state) => state.selectedRelativeTimespan);
  const timespanStart = useSelector((state) => state.timespanStart);
  const timespanEnd = useSelector((state) => state.timespanEnd);
  const isCustomTimePreset = useSelector((state) => state.isCustomTimePreset);
  const lastReloadTime = useSelector((state) => state.lastReloadTime);
  const reloadInterval = useSelector((state) => state.reloadInterval);
  const reloadIntervalRef = useRef();
  const lastReloadTimeRef = useRef();

  reloadIntervalRef.current = reloadInterval;
  lastReloadTimeRef.current = lastReloadTime;

  // ===== Imports =====
  const { canReloadNow } = useReloadTimes();
  const query = useQuery();
  const store = useStore();
  const dispatch = useDispatch();
  const api = useApi();
  const { formatDate } = useDateTimeFormat();
  const phrases = usePhrases().phrases();
  // ===== Effects =====

  useEffect(() => {
    if (socket._callbacks['$STATES_UPDATE'] == undefined) {
      socket.on('STATES_UPDATE', (data) => {
        if(canReloadNow(lastReloadTimeRef.current, reloadIntervalRef.current, 'STATES_UPDATE')){
          dispatch(setLastReloadTime({...lastReloadTimeRef.current, 'STATES_UPDATE': moment().format()}));
          dispatch(storeMachineStates(data));
          setThisTimeUpdated(moment().toDate());       
        } 
      });
    }
    if (socket._callbacks['$SINGLE_MACHINE_STATES_UPDATE'] == undefined) {
      socket.on('SINGLE_MACHINE_STATES_UPDATE', (data) => {
        if(canReloadNow(lastReloadTimeRef.current, reloadIntervalRef.current, 'SINGLE_MACHINE_STATES_UPDATE')){
          dispatch(setLastReloadTime({...lastReloadTimeRef.current, 'SINGLE_MACHINE_STATES_UPDATE': moment().format()}));
          const _machineStates = store.getState().machineStates;
          console.log('SINGLE_MACHINE_STATES_UPDATE');
          dispatch(storeMachineStates({
            ..._machineStates,
            ...data,
          }));
          setThisTimeUpdated(moment()
            .toDate()); 
        }       
      });
    }
    if (socket._callbacks['$HEARTHBEATS_UPDATE'] == undefined) {
      socket.on('HEARTHBEATS_UPDATE', (data) => {
        if(canReloadNow(lastReloadTimeRef.current, reloadIntervalRef.current, 'HEARTHBEATS_UPDATE')){
          dispatch(setLastReloadTime({...lastReloadTimeRef.current, 'HEARTHBEATS_UPDATE': moment().format()}));
          console.log('HEARTHBEATS_UPDATE');
          setSensors(data);
        }
      });
    }
    return () => {
      socket.emit('STATE_REMOVE_LISTENER');
      socket.emit('HEARTHBEAT_REMOVE_LISTENER');
      socket._callbacks['$STATES_UPDATE'] = undefined;
      socket._callbacks['$HEARTHBEATS_UPDATE'] = undefined;
      socket._callbacks['$SINGLE_MACHINE_STATES_UPDATE'] = undefined;
      dispatch(storeListOfMachines([]));
      dispatch(storeMachineStates({}));
      setSensors([]);
      cancelSensors && cancelSensors();
      cancelSensors = undefined;
      cancelMachines && cancelMachines();
      cancelMachines = undefined;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(getProfile().views?.machinesPerPage !== undefined){
      setLazyLoadBatch((prev) => parseInt(getProfile().views.machinesPerPage));
      setPageCount(Math.ceil(allMachines.length / parseInt(getProfile().views.machinesPerPage)));
    }
    else {
      setPageCount(Math.ceil(allMachines.length / lazyLoadBatch));
    }
  }, [allMachines]);

   /**
   * Initial setup
   */
  // TODO: Refactor this crap.
  useEffect(() => {
    const queryIsRelativeTimespan = query.get('isRelativeTimespan');
    if (queryIsRelativeTimespan === null ? isRelativeTimespan : queryIsRelativeTimespan === 'true') {
      const amount = Number(query.get('amount'));
      const unit = query.get('unit');
      const endOfTimespan = moment().toDate();
      const startOfTimespan = moment().subtract(amount || selectedRelativeTimespan.amount, unit || selectedRelativeTimespan.unit)
        .toDate();
      dispatch(setTimespanStart(startOfTimespan));
      dispatch(setTimespanEnd(endOfTimespan));
      dispatch(setIsRelativeTimespan(true));
      dispatch(setTimespanDuration(moment(endOfTimespan)
        .diff(moment(startOfTimespan), 'hours')));
      dispatch(setSelectedRelativeTimespan({
        amount: amount || selectedRelativeTimespan.amount,
        unit: unit || selectedRelativeTimespan.unit,
      }));

      dispatch(setSelectedTimespanText(formatSelectedTimespanText({
        amount: amount || selectedRelativeTimespan.amount,
        unit: unit || selectedRelativeTimespan.unit,
      })));
    } else {
      const queryTimespanStart = query.get('timespanStart') === null ? null : new Date(query.get('timespanStart'));
      const queryTimespanEnd = query.get('timespanEnd') === null ? null : new Date(query.get('timespanEnd'));
      const startOfTimespan = moment(queryTimespanStart || timespanStart)
        .toDate();
      const endOfTimespan = moment(queryTimespanEnd || timespanEnd)
        .toDate();
      dispatch(setTimespanStart(startOfTimespan));
      dispatch(setTimespanEnd(endOfTimespan));
      if(!isCustomTimePreset){
        dispatch(setSelectedTimespanText(`${formatDate(startOfTimespan)} - ${formatDate(endOfTimespan)}`));
      }
      dispatch(setIsRelativeTimespan(false));
      dispatch(setSelectedRelativeTimespan({
        amount: 0,
        unit: '',
      }));
      dispatch(setTimespanDuration(moment(endOfTimespan)
        .diff(moment(startOfTimespan), 'hours')));
    }
    setThisTimeUpdated(moment()
      .toDate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  

  /**
   * Update the timespan on each update of the states through the socket.
   * This effect gets triggered each time when the thisTimeUpdated variable gets updated.
   */
  useEffect(() => {
    if (!thisTimeUpdated) return;
    dispatch(setLastPulseUpdate(thisTimeUpdated));

    if (isRelativeTimespan) {
      const endOfTimespan = moment()
        .toDate();
      const startOfTimespan = moment()
        .subtract(selectedRelativeTimespan.amount, selectedRelativeTimespan.unit)
        .toDate();
      dispatch(setTimespanStart(startOfTimespan));
      dispatch(setTimespanEnd(endOfTimespan));
      dispatch(setTimespanDuration(moment(endOfTimespan)
        .diff(moment(startOfTimespan), 'hours')));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thisTimeUpdated]);

  // ===== Helper Methods =====
  const refreshPulse = (page = 1) => {
    setMachineStates(allMachines);
    if(page > 1){
      // Fetch next batch
      fetchNextBatch(page);
    }
  };
  const getUnitLocalized = ({ amount, unit }) => {
    let localizedUnit = unit;

    // This has to be weak check since in field value is String
    if (amount == 1) {
      switch (unit) {
        default:
          break;
        case 'minutes':
          localizedUnit = phrases.timespanSelection.Minute;
          break;
        case 'hours':
          localizedUnit = phrases.timespanSelection.Hour;
          break;
        case 'days':
          localizedUnit = phrases.timespanSelection.Day;
          break;
        case 'weeks':
          localizedUnit = phrases.timespanSelection.Week;
          break;
      }
    } else {
      switch (unit) {
        default:
          break;
        case 'minutes':
          localizedUnit = phrases.timespanSelection.Minutes;
          break;
        case 'hours':
          localizedUnit = phrases.timespanSelection.Hours;
          break;
        case 'days':
          localizedUnit = phrases.timespanSelection.Days;
          break;
        case 'weeks':
          localizedUnit = phrases.timespanSelection.Weeks;
          break;
      }
    }

    return localizedUnit;
  };

  const formatSelectedTimespanText = ({ amount, unit }) => {
    // This has to be weak check since in field value is String
    if (amount == 1) {
      return `${phrases.timespanSelection.Last} ${getUnitLocalized({
        amount,
        unit,
      })}`;
    }

    return `${phrases.timespanSelection.Last} ${amount} ${getUnitLocalized({
      amount,
      unit,
    })}`;
  };

  /**
   * Fetches the passed in sensors with states(online or offline), and lastSeenAt field updated.
   */
  const setSensorsState = (_sensors) => {
    cancelSensors && cancelSensors();
    api('/api/sensors/online', {
      method: 'post',
      data: {
        sensors: _sensors,
        cancelToken: new CancelToken(function executor(c) {
          cancelSensors = c;
        }),
      },
    })
      .then((response) => {
        if (response.status === 200 || response.status === 304) {
          setSensors(response.data);
          socket.emit('HEARTHBEAT_SET_LISTENER', response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getMachinesSensors = (_machines) => _.flatten(_machines.map((machine) => machine.sensors));

  const changeDataLayer = (_dataLayer) => {};

  /**
   * Fetches the passed in machines with the states array attached to them
   */
  const setMachineStates = (_machines, start = timespanStart, end = timespanEnd, lazyLoad = true, _isRelativeTimespan = isRelativeTimespan) => {
    if(currentPage > 1){
      return fetchNextBatch(currentPage);
    }
    const firstBatchMachines = lazyLoad ? _machines.filter((machine, i) => i < lazyLoadBatch) : _machines;
    setAllMachines(_machines);
    socket.emit('STATE_REMOVE_LISTENER');
    socket.emit('HEARTHBEAT_REMOVE_LISTENER');
    if (_isRelativeTimespan) {
      getMachineStatesTimeframeRelative(firstBatchMachines, start, end);
    } else {
      getMachineStatesTimeframe(firstBatchMachines, start, end);
    }
  };

  const fetchNextBatch = (value) => {
    setCurrentPage(value);
    const nextBatchMachines = allMachines.filter((machine, i) => {
      return i < (value * lazyLoadBatch) && i >= (value - 1) * lazyLoadBatch;
    });
    if (isRelativeTimespan) {
      getMachineStatesTimeframeRelative(nextBatchMachines, timespanStart, timespanEnd, true);
    } else {
      getMachineStatesTimeframe(nextBatchMachines, timespanStart, timespanEnd, true);
    }
  };

  /**
   * Fetches the machines with states in a selected date range.
   *
   * @param {Date} from Start of the date range
   * @param {Date} to End of the date range
   */
  const getMachineStatesTimeframe = (_machines, from, to, overview=true) => {
    setIsLoadingMachines(true);
    cancelMachines && cancelMachines();
    api(`/api/${dataLayer.module}/states`, {
      method: 'post',
      data: {
        machines: _machines.map((machine) => machine.id),
        from,
        to,
        overview: overview,
      },
      cancelToken: new CancelToken(function executor(c) {
        cancelMachines = c;
      }),
    })
      .then((response) => {
        if (response.status === 200 || response.status === 304) {
          socket.emit('STATE_REMOVE_LISTENER');
          dispatch(setTimespanStart(from));
          dispatch(setTimespanEnd(to));
          dispatch(setTimespanDuration(moment(to)
            .diff(moment(from), 'hours')));
          setThisTimeUpdated(moment()
            .toDate());
          dispatch(storeMachineStates(response.data));
          dispatch(storeListOfMachines(_machines));
          setSensorsState(getMachinesSensors(_machines));
          setIsLoadingMachines(false);
        }
      })
      .catch((error) => {
        setIsLoadingMachines(false);
      });
  };

  /**
   * Fetches machines with states attached in a selected, relative timespan.
   *
   * @param {Date} from Start of the relative timespan
   * @param {Date} to End of the relative timespan, should always be now()
   */
  const getMachineStatesTimeframeRelative = (_machines, from, to, overview=true) => {
    setIsLoadingMachines(true);
    cancelMachines && cancelMachines();
    const duration = moment(to)
      .diff(from, 'minutes');
    api(`/api/${dataLayer.module}/states`, {
      method: 'post',
      data: {
        machines: _machines.map((machine) => machine.id),
        duration,
        overview: overview,
      },
      cancelToken: new CancelToken(function executor(c) {
        cancelMachines = c;
      }),
    })
      .then((response) => {
        if (response.status === 200 || response.status === 304) {
          setThisTimeUpdated(moment()
            .toDate());
          dispatch(storeMachineStates(response.data));
          dispatch(storeListOfMachines(_machines));
          setSensorsState(getMachinesSensors(_machines));
          socket.emit('STATE_SET_LISTENER',
            {
              machines: _machines.map((machine) => machine.id)
                .reduce((o, key) => Object.assign(o, {
                  [key]: {
                    ...response.data[key],
                  },
                }), {}),
              timespanDuration: duration,
              dataLayer: dataLayer.dataLayer,
            });

          setIsLoadingMachines(false);
        }
      })
      .catch((error) => {
        setIsLoadingMachines(false);
      });
  };


  // ===== Return =====
  
  return {
    setThisTimeUpdated,
    setMachineStates,
    isLoadingMachines,
    sensors,
    getMachineStatesTimeframe,
    getMachineStatesTimeframeRelative,
    changeDataLayer,
    refreshPulse,
    fetchNextBatch,
    pageCount,
    allMachines
  };
};

export default useMachineWithStates;
