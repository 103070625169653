import React, { useState, useEffect } from 'react';
import useAlert from '@frontend/hooks/useAlert';
import { usePhrases }             from '@frontend/utils/usePhrases';
import { makeStyles } from '@material-ui/core/styles';
import useApi from '@frontend/utils/useApi';
import {
    Button,
    Divider,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    Typography
} from '@material-ui/core';
import DeleteIcon                   from '@material-ui/icons/Delete';

import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
    root: {
      margin: theme.spacing(1),
    },
    formControl: {
      marginBottom: theme.spacing(1),
    },
    firstRow: {
      fontWeight: 'bold',
    },
    searchField: {
        display: 'flex',
        alignItems: 'center',
    },
    btnRight: {
        float: 'right',
        display: 'flex',
    },
}));

const ReviewFlowPickWorkers = (props) => {

    const {
        setWorkers,
    } = props;
    const classes = useStyles();
    const phrases = usePhrases().phrases();
    const { createAlert } = useAlert();

    const api = useApi();
    const [workerId, setWorkerId] = useState('');
    const [selectedWorkers, setSelectedWorkers] = useState([]);

    const handleWorkerChange = (event) => {
        setWorkerId(event.target.value);
    }

    const confirmWorker = () => {
        // Use only the IDs of the selected workers
        setWorkers(selectedWorkers.map((worker) => worker.id));
    }

    const addWorker = () => {
        // Add the worker to the list of selected workers, if not already in the list
        if (!_.find(selectedWorkers, {id: workerId})) {
            api(`/api/users/workerByBusinessId`, {
                params: {
                    businessUserId: workerId
                },
                method: 'get',
            })
            .then((response) => {
                setSelectedWorkers([...selectedWorkers, {id: response.data.businessUserId, name: response.data.name}]);
                setWorkerId('');
            })
            .catch((error) => {
                createAlert(phrases.modules.orders.reviewFlow.noUser, 'error');
            });
        }
    }
    const removeWorker = (workerId) => {    
        setSelectedWorkers(_.filter(selectedWorkers, (worker) => worker.id !== workerId));
    }

    return ( <>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
                <Typography variant="h6" component="h6">
                    {phrases.modules.orders.reviewFlow.enterWorkers}
                </Typography>
            </Grid>
            <Grid item xs={8} sm={8}  className={classes.searchField}>  
                <TextField
                  className={classes.formControl}
                  id="selectedJob-field"
                  name="selectedJob"
                  label={phrases.modules.orders.reviewFlow.workerId}
                  variant="outlined"
                  type="text"
                  fullWidth
                  onChange={handleWorkerChange}
                  value={workerId}
                  margin="dense"
                  required
                  // notched={_.isEmpty(job) ? false : true}
                  // InputLabelProps={{
                  //   shrink: _.isEmpty(job) ? false : true,
                  // }}
                />
              </Grid>
              <Grid item xs={4} sm={4} className={classes.searchField}> 
                <Button onClick={addWorker} disabled={_.isEmpty(workerId)} variant="contained" color="primary" autoFocus className={classes.btnRight}>
                    {phrases.modules.orders.reviewFlow.addWorker}
                </Button>
            </Grid>
            {selectedWorkers.length > 0 &&<>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            
            <Grid item xs={12} sm={12}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell className={classes.firstRow}>
                                {phrases.modules.orders.reviewFlow.workerId}
                            </TableCell>
                            <TableCell className={classes.firstRow}>
                                {phrases.modules.orders.reviewFlow.workerName}
                            </TableCell>
                            <TableCell className={classes.firstRow}>
                                {phrases.modules.orders.reviewFlow.actions}
                            </TableCell>
                        </TableRow>
                        {selectedWorkers.map((worker, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell>
                                        {worker.id}
                                    </TableCell>
                                    <TableCell>
                                        {worker.name}
                                    </TableCell>
                                    <TableCell>
                                    <IconButton
                                        aria-label="delete icon for current row"
                                        aria-haspopup="true"
                                        color="primary"
                                        size="small"
                                        onClick={() => (removeWorker(worker.id))}
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                    </TableCell>
                                </TableRow>
                            )
                        })}                        
                    </TableBody>
                </Table>
            </Grid>
            </>}
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={12} sm={12}> 
                <Button onClick={confirmWorker} disabled={!_.isEmpty(workerId)} variant="contained" color="primary" autoFocus className={classes.btnRight}>
                    {phrases.modules.orders.reviewFlow.confirmWorker}
                </Button>
            </Grid>
        </Grid>
      
    </> );
}
 
export default ReviewFlowPickWorkers;