import React from 'react';
import _ from 'lodash';
import {
    Divider,
    Grid,
    Typography,
} from '@material-ui/core';
import JobNotesDialog from './JobNotesDialog';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { usePhrases } from '@frontend/utils/usePhrases';
import useDateTimeFormat from '@frontend/utils/useDateTimeFormat';

const useStyles = makeStyles((theme) => ({    
    jobControlBtn: {
      width: theme.spacing(30),
    },
    orange: {
      color: theme.palette.primary.main,
    },
    jobInfo: {
      marginLeft: theme.spacing(1),
    },
  }));

const JobBasicInfo = (props) => {
    const { 
        jobTracker,
        loadingPropsObject,
        addNote
    } = props;

    // ===== Imports =====
    const phrases = usePhrases().phrases();
    const classes = useStyles();    
    const { formatDate } = useDateTimeFormat();
    // ===== Return =====
    return ( 
        <>
            <Grid item xs="12">
                <Typography display="inline" color="textSecondary" >
                {phrases.modules.orders.product}:
                </Typography>
                <Typography display="inline" className={classes.jobInfo}>
                {jobTracker?.product?.name || '---'}
                </Typography>
            </Grid>
            <Grid item xs="12">
                <Typography display="inline" color="textSecondary">
                {phrases.modules.orders.plannedStartDate}:
                </Typography>
                <Typography display="inline" className={classes.jobInfo}>
                {!_.isEmpty(jobTracker)
                    ? formatDate(jobTracker?.plannedExecutionDate, false)
                    : '---'}
                </Typography>
            </Grid>
            <Grid item xs="12">
                <Typography display="inline" color="textSecondary">
                {phrases.modules.orders.actualStartDate}:
                </Typography>
                <Typography display="inline" className={classes.jobInfo}>
                {!_.isEmpty(jobTracker)
                    ? formatDate(jobTracker?.actualStartDate, false)
                    : '---'}
                </Typography>
            </Grid>
            <Grid item xs="12">
                <Typography display="inline" color="textSecondary">
                {phrases.modules.orders.endDate}:
                </Typography>
                <Typography display="inline" className={classes.jobInfo}>
                {!_.isEmpty(jobTracker)
                    ? formatDate(jobTracker?.endDate, false)
                    : '---'}
                </Typography>
            </Grid>
            <Grid item xs="12">
                <Typography display="inline" color="textSecondary">
                {phrases.modules.orders.description}:
                </Typography>
                <Typography display="inline" className={classes.jobInfo}>
                {jobTracker?.description || '---'}
                </Typography>
            </Grid>
            <Grid item xs="12">
                <JobNotesDialog 
                jobTracker={jobTracker}
                addNote={addNote}
                loadingPropsObject={loadingPropsObject}/>
            </Grid>
            <Divider/>
            <Grid item xs="12">
                <Typography display="inline" color="textSecondary">
                {phrases.modules.orders.intendedMachine}:
                </Typography>
                <Typography display="inline" className={classes.jobInfo}>
                {jobTracker?.intendedMachine ? (
                    <Link to={`/machine-overview/${jobTracker?.intendedMachine.id}`} className={classes.orange}>
                        {jobTracker?.intendedMachine ? `${jobTracker?.intendedMachine?.name} (${jobTracker?.intendedMachine?.alias})` : '---'}
                    </Link>
                ) : '---'}
                </Typography>
            </Grid>            
            {/* <Grid item xs="12">
                <Typography display="inline" color="textSecondary">
                {jobTracker?.targetCycleTime === 0 ?
                    `${phrases.modules.orders.targetSetupTime}:`
                    :`${phrases.modules.orders.targetCycleTime}:`
                }
                </Typography>
                <Typography display="inline" className={classes.jobInfo}>
                {jobTracker?.targetCycleTime !== undefined ? 
                (jobTracker?.targetCycleTime === 0 ? 
                    `${jobTracker?.targetSetupTime} min`
                    : jobTracker?.targetCycleTime)
                : '---'}
                </Typography>
            </Grid> */}
        </>
     );
}
 
export default JobBasicInfo;