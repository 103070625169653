import { useEffect, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import useAlert from '@frontend/hooks/useAlert';
import useApi from '@frontend/utils/useApi';
import { usePhrases } from '@frontend/utils/usePhrases';
import { CompletionStatusEnum, CustomCompletionStatusEnum, DEFAULT_JOB_ID_LENGTH, HANDCOUNT_THRESHOLD} from '@frontend/constants';
import useJobTracker from '@frontend/modules/orders/hooks/useJobTracker';
const emptyActivation = {
    activationStatus: CustomCompletionStatusEnum.ENDED,
    user: {},
    handCount: 0,
    isSetup: false,
    // partsProduced: 0,
    time: moment().subtract(1, "hour").seconds(0).milliseconds(0).toDate(),
    endTime: moment().seconds(0).milliseconds(0).toDate(),
    // product: {},
    job: {},
    machine: {},
}
const useOrdersExportValidationModal = (activationToEdit, machines, handleCloseModal, isEdit, isReviewFlow=false) => {
    const [errors, setErrors] = useState({});
    const [activationValues, setActivationValues] = useState(_.isEmpty(activationToEdit)? emptyActivation: activationToEdit);
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [toDateDisabled, setToDateDisabled] = useState(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [jobInformation, setJobInformation] = useState({job:{}}); // {product: {businessProductId: "Product 1", id: 1}, job: {businessJobId: "Job 1", id: 1}}
    const [isWorkingOrderComplete, setIsWorkingOrderComplete] = useState(false); // [true, false]
    const [workingOrderCheckEnabled, setWorkingOrderCheckEnabled] = useState(true); // [true, false
    // This is a little hacky but is required because the plain machine Object cannot be compared. Thus, setting a default Machine is not possible.
    const [machineIdNames, setMachineIdNames] = useState([]); // [{id: 1, name: "Machine 1"}, {id: 2, name: "Machine 2"}
    const [selectedMachine, setSelectedMachine] = useState(_.isEmpty(activationToEdit)? {}: activationToEdit.machine); // {id: 1, name: "Machine 1"
    const [handCount, setHandCount] = useState(0); // {id: 1, name: "Machine 1"

    const api = useApi();
    const phrases = usePhrases().phrases();
    const { createAlert } = useAlert();
    
    const {
        fetchJobByBusinessId,
        jobTracker
    } = useJobTracker();


    useEffect(() => {
        setMachineIdNames(machines.map((machine) => {
            return {id: machine.id, name: machine.name}
        }));
        if(machines.length === 1){
            setSelectedMachine(machines[0]);  
        }
        if(!_.isEmpty(activationValues.machine)){
            let foundMachine = machines.find((machine) => machine.id === activationValues.machine.id);  
            if(foundMachine === undefined){
                foundMachine = machines.find((machine) => machine.id === activationToEdit.machine?.id);           
            }  
            setSelectedMachine(foundMachine);
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [machines]);

    useEffect(() => {
        if(!_.isEmpty(jobTracker)){
            setActivationValues({...activationValues, job: {businessJobId: jobTracker.businessJobId, intendedMachine: jobTracker.intendedMachine?.businessId}});
        }             
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobTracker]);

    useEffect(() => {
        if(!_.isEmpty(activationValues.activationStatus) 
            && (   activationValues.activationStatus === CustomCompletionStatusEnum.ACTIVE 
                || activationValues.activationStatus === CompletionStatusEnum.STARTED)){
            setToDateDisabled(true);
            let tmpActivationValues = activationValues;
            tmpActivationValues.endTime = null;
            setActivationValues(tmpActivationValues);
            let errorsWithoutEndDate = errors;
            delete errorsWithoutEndDate.endTime;
            setErrors(errorsWithoutEndDate);
        }
        else{
            setToDateDisabled(false);
            let tmpActivationValues = activationValues;
            tmpActivationValues.endTime = activationValues.endTime ?? moment().seconds(0).milliseconds(0);
            setActivationValues(tmpActivationValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activationValues.activationStatus]);

    useEffect(() => {
        if(!_.isEmpty(activationValues.job) && !_.isEmpty(activationValues.job.completionStatus)){
            setIsWorkingOrderComplete((activationValues.job.completionStatus === CompletionStatusEnum.COMPLETED) && workingOrderCheckEnabled);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activationValues.job]);

    useEffect(() => {
        // Only for Adding Activations
        setWorkingOrderCheckEnabled(selectedMachine?.config?.jobBasedTerminal === "true");
        if(!isEdit && !_.isEmpty(selectedMachine)){
            // Get the assigned Job first
            api(`/api/orders/jobs/assignedJob`, {
                params: {
                    populate: 'product',
                    machineId: selectedMachine.id
                },
                method: 'get',
            })
            .then((response) => {
                if (response.status === 200 && !_.isEmpty(response.data)) {
                    setActivationValues({
                        ...activationValues, 
                        machine: selectedMachine,
                        activationStatus: CustomCompletionStatusEnum[selectedMachine?.config?.defaultActivationStatus && !isReviewFlow] ?? CustomCompletionStatusEnum.ENDED,
                        job: {
                            businessJobId: response.data.businessJobId, 
                            intendedMachine: response.data.intendedMachine?.businessId,
                            completionStatus: response.data.completionStatus
                        }
                    });
                    setJobInformation({
                        job: {
                            intendedMachine: response.data.intendedMachine?.businessId,
                            businessJobId: response.data.businessJobId,
                            completionStatus: response.data.completionStatus,
                            id: response.data.id
                        },
                    });
                } else {
                    // Now fetch the active Job
                    api(`/api/orders/jobs/activeJob/${selectedMachine.id}`, {
                        params: {
                            populate: 'product',
                        },
                        method: 'get',
                    })
                    .then((response) => {
                        if (response.status === 200 && !_.isEmpty(response.data)) {
                            setActivationValues({
                                ...activationValues, 
                                activationStatus: CustomCompletionStatusEnum[selectedMachine?.config?.defaultActivationStatus] ?? CustomCompletionStatusEnum.ENDED,
                                job: {
                                    businessJobId: response.data.businessJobId,
                                    intendedMachine: response.data.intendedMachine?.businessId,
                                    completionStatus: response.data.completionStatus,
                                }
                            });
                            setJobInformation({
                                job: {
                                    intendedMachine: response.data.intendedMachine?.businessId,
                                    businessJobId: response.data.businessJobId, 
                                    id: response.data.id,
                                    completionStatus: response.data.completionStatus
                                },
                            });
                        }
                    }).catch((error) => {
                        console.log(error);
                    });

            }}).catch((error) => {
                setActivationValues({
                    ...activationValues,
                    activationStatus: CustomCompletionStatusEnum[selectedMachine?.config?.defaultActivationStatus] ?? CustomCompletionStatusEnum.ENDED,
                    job: {businessJobId: ""}
                });
                setJobInformation({
                    job: {      
                        businessJobId: ""                  
                    },
                });
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMachine]);

    useEffect(() => {
        let tmpActivationValues = activationValues;
        if(machines.length === 1){
            tmpActivationValues = {
                ...tmpActivationValues,
                machine: machines[0]
            };
        }
        tmpActivationValues = {
            ...tmpActivationValues,
            user: {
                ...tmpActivationValues.user,
                email: isEdit || isReviewFlow ? tmpActivationValues.user.email: ""
            }
        };
        setActivationValues(tmpActivationValues);
        
        if(!_.isEmpty(tmpActivationValues.machine)){
            // Fetch the assigned Job
            api(`/api/orders/jobs/assignedJob`, {
                params: {
                    populate: 'product',
                    machineId: tmpActivationValues.machine.id,
                },
                method: 'get',
            })
            .then((response) => {
                if (response.status === 200 && !_.isEmpty(response.data)) {
                    setActivationValues({
                        ...activationValues, 
                        job: {
                            businessJobId: response.data.businessJobId, 
                            intendedMachine: response.data.intendedMachine?.businessId,
                            completionStatus: response.data.completionStatus
                    }});
                    setIsWorkingOrderComplete((response.data.completionStatus === CompletionStatusEnum.COMPLETED) && workingOrderCheckEnabled)
                    setJobInformation({
                        job: {
                            intendedMachine: response.data.intendedMachine?.businessId,
                            businessJobId: response.data.businessJobId,
                            id: response.data.id
                        },
                    });
                }
            }).catch((error) => {
                console.log(error);
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(moment(activationValues.endTime).isBefore(moment(activationValues.time))){
            setErrors({...errors, activationPresent: phrases.modules.order_export.errors.endTimeBeforeStartTime});
        } else{
            setErrors({...errors, activationPresent: undefined});
            // Check if an activation is already present
            api('/api/orders/jobs/isActivationPresent', {
                params: {
                    machineId: activationValues.machine.id,
                    timespanStart: moment(activationValues.time)
                                    .seconds(0)
                                    .milliseconds(0)
                                    .toISOString(),
                    timespanEnd: activationValues.endTime ? 
                                    moment(activationValues.endTime)
                                        .second(0)
                                        .milliseconds(0)
                                        .toISOString()
                                :   moment()
                                        .seconds(0)
                                        .milliseconds(0)
                                        .toISOString(),
                },
                method: 'get',
            })
            .then((response) => {
                if (response.status === 200 && response.data === true && !isEdit) {
                    setErrors({...errors, activationPresent: phrases.modules.order_export.errors.activationAlreadyPresent});
                    createAlert(phrases.modules.order_export.errors.activationAlreadyPresent, 'error');
                }
            }).catch((error) => {
                console.log(error);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activationValues.time, activationValues.endTime]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        // ACTIVE -> Set to Date disabled and set it to now
        if(name === "activationStatus" && (value.value === CustomCompletionStatusEnum.ACTIVE || value.value === CompletionStatusEnum.STARTED)){
            setToDateDisabled(true);
            let tmpActivationValues = activationValues;
            tmpActivationValues.endTime = null;
            tmpActivationValues.activationStatus = value.value;
            setActivationValues(tmpActivationValues);
            return;
        } else if (name === "activationStatus"){
            setToDateDisabled(false);
            let tmpActivationValues = activationValues;
            tmpActivationValues.activationStatus = value.value;
            tmpActivationValues.endTime = moment().seconds(0).milliseconds(0);
            setActivationValues(tmpActivationValues);
            return;
        // } else if (name === "activationStatus" && value.value === CustomCompletionStatusEnum.ENDED){            
        //     setToDateDisabled(false);
        } 
        if(name === "machine"){
            // Set the machine field
            let foundMachine = machines.find((machine) => machine.id === value.id);
            setActivationValues({ ...activationValues, machine: foundMachine});
            setSelectedMachine(foundMachine);
        }
        else if(name === "user"){
            // Set the email field
            setActivationValues({ ...activationValues, user: {...activationValues.user, email: value, name: value, businessUserId: value}});
        } 
        else if(name === "isSetup"){
            setActivationValues({ ...activationValues, isSetup: event.target.checked});
        }
        else if(name === "isWorkingOrderComplete"){
            setIsWorkingOrderComplete(event.target.checked);
        }
        else {
            setActivationValues({ ...activationValues, [name]: value });
        }
    };

    const handleChangeHandCount = (hc) => {
        if(hc.target !== undefined){
            // event
            hc = hc.target.value;
        }
        if (Number.isNaN(Number(hc))) {
            setHandCount('');
            setActivationValues({ ...activationValues, handCount: '' });
            return;
        }
        setHandCount(hc);
        checkForHandcount({ ...activationValues, handCount: hc });
        setActivationValues({ ...activationValues, handCount: hc });
    };

    const handleJobChange = (event) => {
        const { value } = event.target;
        
        // So first we just change the job field. Then the jobtracker will search for the correct job and set it in the real data 
        if(value.length >= DEFAULT_JOB_ID_LENGTH){
            fetchJobByBusinessId(value);    
        }
        setJobInformation({...jobInformation, job: {businessJobId: value}});    
    }

    const handleChangeStartTime = (date) => {
        checkForHandcount(activationValues);
        setActivationValues({ 
            ...activationValues, 
            time: moment(date)
                    .seconds(0)
                    .milliseconds(0)
                    .toISOString() 
        });
    };
    const handleChangeEndTime = (date) => {
        checkForHandcount(activationValues);
        setActivationValues({ 
            ...activationValues, 
            endTime: moment(date)
                        .seconds(0)
                        .milliseconds(0)
                        .toISOString() 
        });
    };

    const handleClearForm = () => {
        setFormSubmitting(false);
        setErrors({});
        setActivationValues({...emptyActivation, machine: machines[0], job: activationValues.job});
    };
    const handleSubmit = (event) => {
        event?.preventDefault();
        setFormSubmitting(true);   
        updateOrCreateActivation(activationValues, handleCloseModal, handleClearForm);   
    }

    const checkForHandcount = (values) => {
        api('/api/orders/jobs/producedParts', {
            params: {
                machineId: values.machine.id,
                timespanStart: moment(values.time).format(),
                timespanEnd: values.endTime? moment(values.endTime).format(): moment().format(),        
            },
            method: "get"
        }).then((response) => {
            if (response.status === 200) {
                if(response.data?.actualCount === 0){
                    createAlert(phrases.modules.order_export.noPartsProduced, 'warning');
                }
                else if(values.handCount / response.data?.actualCount > HANDCOUNT_THRESHOLD){
                    createAlert(phrases.modules.order_export.handCountWarning, 'warning');
                }
            }
        }).catch((error) => {
            console.log(error);
        }
        );
    }

    const handleCloseConfirmationModal = () => {
        setConfirmationModalOpen(false);
    }

    const handleConfirmIntendedMachine = () => {
        setConfirmationModalOpen(false);
        setErrors({...errors, intendedMachine: 0});
        handleSubmit();
    }

    useEffect(() => {
        setConfirmationModalOpen(errors.intendedMachine !== undefined && errors.intendedMachine > 0);
    }, [errors.intendedMachine]);

    const validateForm = (values, lite=false) => {
        let _errors = {};
        if(errors.activationPresent !== undefined){
            _errors.activationPresent = errors.activationPresent;
        }
        
        if (!Object.keys(CompletionStatusEnum).includes(values.activationStatus)) {
            _errors.activationStatus = phrases.modules.order_export.errors.activationStatusRequired;
        }
        if (!values.user || _.isEmpty(values.user) || !values.user.email || values.user.email === '') {
            _errors.user = phrases.modules.order_export.errors.operatorRequired;
        }
        if (values.handCount === null || values.handCount === undefined || values.handCount === '' || values.handCount < 0) {
            _errors.handCount = phrases.modules.order_export.errors.handCountRequired;
        }
        if (!values.time || moment(values.time).isAfter(moment())) { 
            _errors.time = phrases.modules.order_export.errors.startTimeRequired;
        }
        if ((!values.endTime && (values.activationStatus !== CustomCompletionStatusEnum.ACTIVE && values.activationStatus !== CompletionStatusEnum.STARTED)) || moment(values.endTime).isAfter(moment())) {
            _errors.endTime = phrases.modules.order_export.errors.endTimeRequired;
        }
        if (values.endTime && moment(values.endTime).isBefore(moment(values.time))) {
            _errors.endTime = phrases.modules.order_export.errors.endTimeBeforeStartTime;
        }
        if (!values.job || _.isEmpty(values.job)) {
            _errors.job = phrases.modules.order_export.errors.jobRequired;
        }
        if (!values.machine || _.isEmpty(values.machine)) {
            _errors.machine = phrases.modules.order_export.errors.machineRequired;
        }
        if(!lite){
            if(values.job?.intendedMachine !== values.machine?.businessId){
                if(errors.intendedMachine === undefined){
                    _errors.intendedMachine = 1;
                }
                else if(errors.intendedMachine === 0){
                    delete _errors.intendedMachine;
                }
            }
        }
        setErrors(_errors);
        return _errors;
    }
    const updateOrCreateActivation = (activationValues, handleCloseModal, handleClearForm) => {
        if(_.isEmpty(validateForm(activationValues))){          
        
            api('/api/orders/jobs/machineActivations', {
                data: {                
                        ...activationValues,
                        machine: activationValues.machine.id,  
                        job: activationValues.job.businessJobId, 
                        isWorkingOrderComplete: isWorkingOrderComplete,             
                },
                method: isEdit ? 'put': 'post',
            })
            .then((response) => {
                if (response.status === 201) {
                    createAlert(phrases.modules.order_export.activationAdded, 'success');
                    handleClearForm();        
                    return true;
                } else if (response.status === 200) {
                    createAlert(phrases.modules.order_export.activationUpdated, 'success');
                    handleCloseModal();            
                    return true;
                }
                setFormSubmitting(false);  
                return false;
            }).catch((error) => {
                if (error.response.status === 404){
                    let errorMessage = phrases.modules.order_export.genericError;
                    switch(error.response.data.problem){
                        case 'user': 
                            errorMessage = phrases.modules.order_export.userNotFound;
                            break;
                        case 'job':
                            errorMessage = phrases.modules.order_export.jobNotFound;
                            break;
                        // case 'product':
                        //     errorMessage = phrases.modules.order_export.productNotFound;
                        //     break;
                        default:
                            break;
                    }
                    createAlert(errorMessage, 'error');
                } else {
                    const errorMessage = phrases.modules.order_export.genericError;
                    createAlert(errorMessage, 'error');
                    console.log(error);
                }
                setFormSubmitting(false);  
                return false;
            });
        } else{
            setFormSubmitting(false);  
            return false;
        }
    }
    
    return {
        handleChange,
        handleJobChange,
        handleChangeStartTime,
        handleChangeEndTime,
        handleClearForm,
        handleSubmit,
        formSubmitting,
        errors,
        updateOrCreateActivation,
        activationValues,
        toDateDisabled,
        machineIdNames,
        selectedMachine,
        jobInformation,
        handCount,
        handleChangeHandCount,
        handleConfirmIntendedMachine,
        handleCloseConfirmationModal,
        confirmationModalOpen,
        isWorkingOrderComplete,
        workingOrderCheckEnabled,
        validateForm
    };
}
 
export default useOrdersExportValidationModal;