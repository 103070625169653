import React from 'react';
import moment from 'moment';
import {
    Button,
    Divider,
    List,
    ListItem,
    ListItemText,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import loader from '@frontend/loader/loader';
import useStatesFilter          from '@frontend/hooks/useStatesFilter';
import useDateTimeFormat        from '@frontend/utils/useDateTimeFormat';
import useConditionalRender     from '@frontend/utils/useConditionalRender';
import { usePhrases }           from '@frontend/utils/usePhrases';
import ReasonTooltipIndicator   from '@frontend/modules/reasons/components/ReasonTooltipIndicator';
import ReasonTooltipButton      from '@frontend/modules/reasons/components/ReasonTooltipButton';
import QualityReport            from '@frontend/modules/quality/components/QualityReport';
import { ALLOW_MERGE_STATE, RUNNING_STATES }      from '@frontend/constants';

const useStyles = makeStyles((theme) => ({
    bold: {
      fontWeight: 900,
    },
    uppercase: {
      textTransform: 'uppercase',
    },
    listItemText: {
      marginRight: theme.spacing(1),
    },
    popupListItem: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    listItemButton: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
  }));

const MachineStatePopperDetailed = (props) => {
    const {
        canSplitState,
        canMergeState,
        machine,
        refreshPulse,
        setIsEdit,
        setReasonAssignModalOpen,
        setScrapReportModalOpen,
        setSplitStateModalOpen,
        setMergeStateModalOpen,
        selectedState,        
        cleanPulseLayout
    } = props
    // ===== State Objects =====

    // ===== Redux Selectors =====
    // ===== Imports =====
    const { conditionalRender } = useConditionalRender();
    const { moduleLoaded } = loader();
    const phrases = usePhrases().phrases();
    const classes = useStyles();
    const {
        formatDate,
        formatHoursMinutesDuration,
        toLocaleNumber
    } = useDateTimeFormat();
    const {
        stateColor,
        stateText,
        isRunningState,
    } = useStatesFilter();

    // ===== Effects =====

    // ===== Local Variables =====
    // ===== Helper Methods =====
    const openSplitStateModal = () => {
        setSplitStateModalOpen(true);
    };

    const openMergeStateModal = () => {
        setMergeStateModalOpen(true);
    };

    const handleAssignReason = () => {
        setIsEdit(false);
        setReasonAssignModalOpen(true);
    };

    const calculateStateDuration = (state, units = 'minutes') => Math.round(
        moment(state.endTime
        ? state.endTime
        : new Date())
        .diff(moment(state.startTime), units, true),
    );
    // ===== Return =====
    return (    
        <List disablePadding dense>
            <ListItem dense className={classes.popupListItem}>
            <Typography
                align="center"
                style={{ color: stateColor(selectedState.state) }}
                className={`${classes.bold} ${classes.uppercase}`}
            >
                {stateText(selectedState.state)}
            </Typography>
            </ListItem>
            {conditionalRender(moduleLoaded('reasons'),
                <ReasonTooltipIndicator
                    selectedState={selectedState}
                />)
            }
            <Divider variant="middle" />
            {conditionalRender(moduleLoaded('performance'),
            <>
                {isRunningState(selectedState.state) && (
                <ListItem dense className={classes.popupListItem}>
                    <ListItemText style={{
                        flex: 1,
                        display: 'block',
                    }}
                    >
                        <Typography variant="subtitle2" className={classes.listItemText}>
                        {phrases.modules.performance.partsProduced}
                        :
                        </Typography>
                    </ListItemText>
                    <Typography variant="subtitle2">
                        {selectedState.state ? toLocaleNumber(Math.round(selectedState.state.actualCount)) || 0 : 0}
                    </Typography>
                </ListItem>)}
                <ListItem dense className={classes.popupListItem}>
                    <ListItemText style={{
                        flex: 1,
                        display: 'block',
                    }}
                    >
                        <Typography variant="subtitle2" className={classes.listItemText}>
                        {selectedState.state && selectedState.state.deviation <= 0 ? phrases.modules.performance.partsMissed : phrases.modules.performance.partsSurplus}
                        :
                        </Typography>
                    </ListItemText>
                    <Typography variant="subtitle2">
                        {selectedState.state ? toLocaleNumber(Math.abs(Math.round(selectedState.state.deviation))) || 0 : 0}
                    </Typography>
                </ListItem>
                {isRunningState(selectedState.state) && (
                <ListItem dense className={classes.popupListItem}>
                    <ListItemText style={{
                        flex: 1,
                        display: 'block',
                    }}
                    >
                        <Typography variant="subtitle2" className={classes.listItemText}>
                        {phrases.modules.machinePerformance.avgPartsPerMinute}
                        :
                        </Typography>
                    </ListItemText>
                    <Typography variant="subtitle2">
                        {selectedState.state ? toLocaleNumber(Math.abs(Math.round(selectedState.state.actualCount/calculateStateDuration(selectedState)*100)/100)) || 0 : 0}
                    </Typography>
                </ListItem>)}
                <Divider variant="middle" />
            </>)
            }
            <ListItem dense className={classes.popupListItem}>
                <ListItemText style={{
                    flex: 1,
                    display: 'block',
                }}
                >
                    <Typography variant="subtitle2" className={classes.listItemText}>
                    {phrases.timespanSelection.from}
                    :
                    </Typography>
                </ListItemText>
                <Typography variant="subtitle2">
                    {selectedState.startTime ? formatDate(selectedState.startTime) : ' - '}
                </Typography>
            </ListItem>
            <ListItem dense className={classes.popupListItem}>
                <ListItemText>
                    <Typography variant="body2" className={classes.listItemText}>
                    {phrases.timespanSelection.to}
                    :
                    </Typography>
                </ListItemText>
                <Typography variant="body2">
                    {selectedState.endTime ? formatDate(selectedState.endTime) : ' - '}
                </Typography>
            </ListItem>
            <ListItem dense className={classes.popupListItem}>
                <ListItemText>
                    <Typography variant="body2" className={classes.listItemText}>
                    {phrases.modules.machine.duration}
                    :
                    </Typography>
                </ListItemText>
                <Typography variant="body2">
                    {formatHoursMinutesDuration(calculateStateDuration(selectedState))}
                </Typography>
            </ListItem>
            {conditionalRender(moduleLoaded('reasons')
            && !RUNNING_STATES.includes(selectedState.state["presentation.status"]),
            (
                <ReasonTooltipButton
                selectedState={selectedState}
                handleAssignReason={handleAssignReason}
                />))}
            {conditionalRender(moduleLoaded('quality') && !cleanPulseLayout,
            (
                <QualityReport
                refreshPulse={refreshPulse}
                machine={machine}
                popup
                actualCount={selectedState.state.actualCount}
                selectedState={selectedState}
                setParentModalOpen={setScrapReportModalOpen}
                />
            ))}
            {conditionalRender(!cleanPulseLayout,(
            <ListItem dense className={classes.popupListItem}>
                <Button
                    size="small"
                    className={classes.listItemButton}
                    onClick={openSplitStateModal}
                    variant="contained"
                    disableElevation
                    disabled={!canSplitState}
                >
                    {phrases.modules.machine.splitState}
                </Button>
            </ListItem>)
            )}
            { ALLOW_MERGE_STATE && !cleanPulseLayout &&
            <ListItem dense className={classes.popupListItem}>
                <Button
                    size="small"
                    className={classes.listItemButton}
                    onClick={openMergeStateModal}
                    variant="contained"
                    disableElevation
                    disabled={!canMergeState}
                >
                    {phrases.modules.machine.mergeState}
                </Button>
            </ListItem>
            }
      </List>
      
    );
}

export default MachineStatePopperDetailed;
