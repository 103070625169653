import React, { useState } from 'react';
import useReviewFlowPulse from '@frontend/modules/orders/hooks/useReviewFlowPulse';
import LoadingIndicator from '@frontend/components/LoadingIndicator';
import { usePhrases }             from '@frontend/utils/usePhrases';
import MachinePulseBar from '@frontend/modules/machine/MachinePulseBar';
import { Typography } from '@material-ui/core';

import { 
    makeStyles
  } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    heading: {
        textAlign: 'center',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        margin: 'auto',
        marginBottom: theme.spacing(2),
    },
    textCenter: {
        textAlign: 'center',
        margin: 'auto',
        fontWeight: 'bold',
        color: theme.palette.secondary.main,
    },
    
}));

const ReviewFlowPulse = (props) => {
    const {
        timeframeStart,
        timeframeEnd,
        machine,
    } = props;
    
    const classes = useStyles();
    const phrases = usePhrases().phrases();
    const { machineStates, loading, reloadStates } = useReviewFlowPulse(machine, timeframeStart, timeframeEnd);


    return (<>
        <Typography variant="h5" className={classes.heading}>
            {phrases.modules.orders.reviewFlow.machinePulse}
        </Typography>
        <Typography variant="h6" gutterBottom >
            {phrases.modules.orders.reviewFlow.pulseText} {" "}
            <span className={classes.textCenter}>{machine.name}</span>
        </Typography>
    {loading ?  
        <LoadingIndicator light text={phrases.misc.loading} />:
        <MachinePulseBar
            machine={machine}
            data={machineStates[machine.id] ? machineStates[machine.id].states : []}
            refreshPulse={reloadStates}
            overview={false}
            customTimespanStart={timeframeStart}
            customTimespanEnd={timeframeEnd}
            cleanPulseLayout={true}
        />
    }
    </>);
}
 
export default ReviewFlowPulse;