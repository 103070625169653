import React, { useState, useEffect } from 'react';
import useJobTracker from '@frontend/modules/orders/hooks/useJobTracker';
import { DEFAULT_JOB_ID_LENGTH} from '@frontend/constants';
import { usePhrases }             from '@frontend/utils/usePhrases';
import ConfirmationModal from '@frontend/components/ConfirmationModal';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    Typography
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    root: {
      margin: theme.spacing(1),
    },
    formControl: {
      marginBottom: theme.spacing(2),
    },
    firstRow: {
      fontWeight: 'bold',
    },
}));

const ReviewFlowPickJob = (props) => {

    const {
        job,
        setJob
    } = props;
    const classes = useStyles();
    const phrases = usePhrases().phrases();
    const [jobConfirmationModalOpen, setJobConfirmationModalOpen] = useState(false);

    const {
        fetchJobByBusinessId,
        jobTracker
    } = useJobTracker();
    
    const handleCloseJobConfirmationModal = () => {
        setJobConfirmationModalOpen(false);
        setJob({});
    }
  
    const confirmationModalText = () => {
        return (<>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <Typography variant="body1" component="p" className={classes.firstRow}>
                                {phrases.modules.orders.reviewFlow.jobID}
                                {":"}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            {jobTracker.businessJobId}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography variant="body1" component="p" className={classes.firstRow}>
                                {phrases.modules.orders.reviewFlow.jobDescription}
                                {":"}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            {jobTracker?.description}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography variant="body1" component="p" className={classes.firstRow}>
                                {phrases.modules.orders.reviewFlow.machineName}
                                {":"}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            {jobTracker?.intendedMachine?.name}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography variant="body1" component="p" className={classes.firstRow}>
                                {phrases.modules.orders.reviewFlow.productName}
                                {":"}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            {jobTracker?.product?.name}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            
            
            
            
        </>);
    }
    // Fetch the Job for the machine
    const handleJobChange = (event) => {
        const { value } = event.target;            
        if(value.length >= DEFAULT_JOB_ID_LENGTH){
            fetchJobByBusinessId(value);    
        }
    }
  
    useEffect(() => {
        // Show Modal
        if(jobTracker.businessJobId){
            setJobConfirmationModalOpen(true);
        }
        // setJob(jobTracker);
    }, [jobTracker]);
  

    return ( <>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
                <Typography variant="h6" component="h6">
                    {phrases.modules.orders.reviewFlow.enterJobDetails}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>  
                <TextField
                  className={classes.formControl}
                  id="selectedJob-field"
                  name="selectedJob"
                  label={phrases.modules.order_export.job}
                  variant="outlined"
                  type="text"
                  fullWidth
                  onChange={handleJobChange}
                  value={job.businessJobId}
                  margin="dense"
                  required
                  // notched={_.isEmpty(job) ? false : true}
                  // InputLabelProps={{
                  //   shrink: _.isEmpty(job) ? false : true,
                  // }}
                />
              </Grid>
        </Grid>
        <ConfirmationModal
            title={phrases.modules.orders.reviewFlow.confirmJobModalTitle}
            text={confirmationModalText()}
            openModal={jobConfirmationModalOpen}
            handleClose={handleCloseJobConfirmationModal}
            callback={() => { setJob(jobTracker); }}
        />
    </> );
}
 
export default ReviewFlowPickJob;