import React, {useState} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

import Modal                      from '@frontend/components/Modal';
import { ROUTES }                 from '@frontend/constants';
import ReviewFlowContent          from '@frontend/modules/orders/ReviewFlowContent';
import ReviewFlowPickJob          from '@frontend/modules/orders/components/ReviewFlowPickJob';
import ReviewFlowPickMachine      from '@frontend/modules/orders/components/ReviewFlowPickMachine';
import ReviewFlowPickWorkers      from '@frontend/modules/orders/components/ReviewFlowPickWorkers';
import { usePhrases }             from '@frontend/utils/usePhrases';
import { setMachineInUse }        from '@frontend/utils/UIActions';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
  },
  formControl: {
    marginBottom: theme.spacing(2),
  },
}));
const ReviewEntryFlowModal = (props) => {
    const classes = useStyles();
    const phrases = usePhrases().phrases();
    const dispatch = useDispatch();
    const history = useHistory();
    const { 
      isOpen, 
      handleClose, 
      onEnter,
    } = props;

    const [job, setJob] = useState({});
    const [selectedMachine, setSelectedMachine] = useState({});
    const [workers, setWorkers] = useState([]);

    const handleCloseReviewFlow = () => {
      // Set selected Machine in Redux 
      dispatch(setMachineInUse(selectedMachine.id));
      history.push(`${ROUTES.ORDERS_EXPORT_VALIDATION}/${selectedMachine.structure}`);
      setJob({});
      setSelectedMachine({});
      setWorkers([]);
      handleClose();
    }


    return (<>
    <Modal
        modalTitle={phrases.modules.orders.reviewFlow.title}
        open={isOpen}
        handleClose={handleCloseReviewFlow}
        onEnter={onEnter}
        customSize="xl"
        content={
          _.isEmpty(job) ?
            <ReviewFlowPickJob 
              job={job}
              setJob={setJob}
            />
            : _.isEmpty(selectedMachine) ?
              <ReviewFlowPickMachine
                job={job}
                setSelectedMachine={setSelectedMachine}
              />
              : _.isEmpty(workers) ?            
                <ReviewFlowPickWorkers
                  setWorkers={setWorkers}
                />
                : <ReviewFlowContent
                    handleClose={handleCloseReviewFlow}
                    job={job}
                    selectedMachine={selectedMachine}
                    workers={workers}
                  />
        }
    />
    </>);
}
 
export default ReviewEntryFlowModal;