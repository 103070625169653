import React, { forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useViewport } from 'react-viewport-hooks';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Zoom,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(1),
  },
  modal: {
    minWidth: 500,
  },
}));

// eslint-disable-next-line react/jsx-props-no-spreading
const Transition = forwardRef((props, ref) => <Zoom {...props} ref={ref} />);
Transition.displayName = 'Modal transition';

const Modal = (props) => {
  const classes = useStyles();
  const {
    modalTitle,
    open,
    handleClose,
    onEnter,
    content,
    
  } = props;
  const { vw } = useViewport();
  const [size, setSize] = React.useState("md");
  useEffect(() => {
    if(vw < 1367){
        setSize("false");
    } else if(vw < 1500){
      setSize("lg");      
    } else if(vw < 1921){
        setSize("md");        
    } else {
        setSize("md");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vw]);


  return (
    <Dialog
      fullWidth
      scroll="body"
      maxWidth= {props.customSize ?? size}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      PaperProps={{
        square: true,
      }}
      disableRestoreFocus
      onEnter={onEnter}
      disableBackdropClick
      disableEscapeKeyDown
      {...props}
    >
      <AppBar className={classes.appBar}>
        <Toolbar variant="dense">
          <Typography variant="h6" className={classes.title}>
            {modalTitle}
          </Typography>
          <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <div className={classes.toolbar} />
        {content}
      </DialogContent>
    </Dialog>
  );
};

Modal.propTypes = {
  modalTitle: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  content: PropTypes.node.isRequired,
};

export default Modal;
