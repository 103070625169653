/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import {
  Backdrop,
  Collapse,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { makeStyles }           from '@material-ui/core/styles';
import { DeleteForever, Edit }  from '@material-ui/icons';
import { faExclamationTriangle }  from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';

import LoadingIndicator   from '@frontend/components/LoadingIndicator';
import CustomTabs         from '@frontend/components/Tabs';
import { UNDEFINED_STATES }   from '@frontend/constants'
import useStatesFilter      from '@frontend/hooks/useStatesFilter';
import { usePhrases }     from '@frontend/utils/usePhrases';
import useReasonCollapse from '@frontend/modules/reasons/hooks/useReasonCollapse';

const useStyles = makeStyles((theme) => ({
    root: {
      margin: theme.spacing(1),
      width: '100%',
    },
    inputField: {
      width: '100%',
    },
    expand: {
      marginLeft: 'auto',
    },
    uppercase: {
      textTransform: 'uppercase',
    },
    backdrop: {
      zIndex: 1,
      position: 'fixed',
      margin: 'auto',
    },
  }));

const ReasonsCollapse = (props) => {    
    const {
        machineId,
        modalOpen,
        statusReasonValues,
        handleDeleteReason,
        handleChangeNote,
        handleReasonClick,
        selectedState
    } = props;

    const [reasonsTabsCollapseOpen, setReasonsTabsOpen] = useState(true);

    const classes = useStyles();
    const phrases = usePhrases().phrases();
    const {
        reasonsByCategory,
        loadingReasons
    } = useReasonCollapse(modalOpen, machineId);

    const {
        reasonText
    } = useStatesFilter();
  
    // ===== Effects =====
    useEffect(() => {
        setReasonsTabsOpen(!statusReasonValues.identifier);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusReasonValues]);
    
    useEffect(() => {
      if(selectedState !== undefined){
        setReasonsTabsOpen(UNDEFINED_STATES.includes(selectedState.state["presentation.reason"]));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedState])
    

    return ( 
        <>
        <Grid item xs={12}>
            <Collapse in={!reasonsTabsCollapseOpen} timeout="auto" unmountOnExit>
                <ListItem
                 selected
                 style={{
                        borderLeft: `5px solid ${statusReasonValues.color}`,
                        backgroundColor: `${statusReasonValues.color}33`,
                        }}
                >
                    <ListItemText
                      primary={reasonText(statusReasonValues.identifier)}
                    />
                    <Tooltip title="Change">
                      <IconButton
                        className={clsx(classes.expand)}
                        onClick={() => setReasonsTabsOpen(true)}
                        aria-expanded={!reasonsTabsCollapseOpen}
                        aria-label="show more"
                      >
                        <Edit />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Delete">
                      <IconButton
                        className={clsx(classes.expand)}
                        onClick={() => handleDeleteReason()}
                        aria-expanded={!reasonsTabsCollapseOpen}
                        aria-label="remove reason"
                      >
                        <DeleteForever />
                      </IconButton>
                    </Tooltip>
                  </ListItem>
                  <TextField
                    id="note"
                    name="note"
                    label={phrases.forms.reason.misc.note}
                    multiline
                    rows={3}
                    variant="outlined"
                    fullWidth
                    className={classes.inputField}
                    onChange={handleChangeNote}
                    value={statusReasonValues.note}
                    margin="dense"
                  />
                </Collapse>
              </Grid>
              <Grid item xs={12} style={{ position: 'relative' }}>
                <Collapse
                  style={{ position: 'relative' }}
                  in={reasonsTabsCollapseOpen}
                  timeout="auto"
                  unmountOnExit
                >
                  <CustomTabs
                    tabs={Object.keys(reasonsByCategory)
                      .map((category) => ({
                        label: category,
                        icon: faExclamationTriangle,
                      }))}
                    panels={
                      Object.keys(reasonsByCategory)
                        .map((category) => ({
                          content: (
                            <List>
                              <Grid container spacing={1}>
                                {reasonsByCategory[category].map((reason) => (
                                  <Grid item xs={12} md={6}>
                                    <ListItem
                                      dense
                                      button
                                      style={{
                                        borderLeft: `5px solid ${reason.color}`,
                                        backgroundColor: `${reason.color}33`,
                                        textTransform: 'uppercase',
                                      }}
                                      selected={reason.identifier === statusReasonValues.identifier}
                                      onClick={() => handleReasonClick(reason)}
                                    >
                                      <ListItemText
                                        primary={reasonText(reason.identifier)}
                                      />
                                    </ListItem>
                                  </Grid>
                                ))}

                              </Grid>
                            </List>),
                        }))
                    }
                  />
                </Collapse>
                <Backdrop classes={{ root: classes.backdrop }} open={loadingReasons}>
                  :
                  {' '}
                  <LoadingIndicator light text={phrases.forms.reason.misc.loadingReasons} />
                </Backdrop>
              </Grid>
              </>
     );
}
 
export default ReasonsCollapse;