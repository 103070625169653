import { useEffect, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import useAlert from '@frontend/hooks/useAlert';
import useApi from '@frontend/utils/useApi';
import { usePhrases } from '@frontend/utils/usePhrases';
import { CompletionStatusEnum, CustomCompletionStatusEnum, DEFAULT_JOB_ID_LENGTH, HANDCOUNT_THRESHOLD} from '@frontend/constants';
const emptyActivation = {
    activationStatus: CustomCompletionStatusEnum.ENDED,
    user: {},
    handCount: 0,
    isSetup: false,
    // partsProduced: 0,
    time: moment().seconds(0).milliseconds(0).toDate(),
    endTime: null,
    // product: {},
    job: {},
    machine: {},
}
const useReviewFlowRecord = (activationToEdit, job={}, operatorNames=[], selectedMachine={}, setGlobalActivation=()=>{}) => {
    const [errors, setErrors] = useState({});
    const [activationValues, setActivationValues] = useState(emptyActivation);
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [toDateDisabled, setToDateDisabled] = useState(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [jobInformation, setJobInformation] = useState({job:{}}); // {product: {businessProductId
    const [handCount, setHandCount] = useState(0); // {id: 1, name: "Machine 1"

    const api = useApi();
    const phrases = usePhrases().phrases();
    const { createAlert } = useAlert();
    
    useEffect(() => {
        let tmpActivation = activationValues
        if(!_.isEmpty(activationToEdit)){
            tmpActivation = {
                ...activationToEdit,
            };
        }
        if(!_.isEmpty(job)){
            setJobInformation({job: job});
            tmpActivation = {
                ...tmpActivation, 
                job: {
                    businessJobId: job.businessJobId, 
                    intendedMachine: job.intendedMachine?.businessId,
                    product: job.product,
                },
            };
        }
        if(!_.isEmpty(selectedMachine)){
            tmpActivation = {
                ...tmpActivation, 
                machine: selectedMachine,
            };
        }
        if(!_.isEmpty(operatorNames)){
            let operatorNamesString = operatorNames.join(", ");
            tmpActivation = {
                ...tmpActivation,  
                user: {
                    ...activationValues.user, 
                    name: operatorNamesString, 
                    email: operatorNamesString, 
                    businessUserId: operatorNamesString
                }
            };
        }
        setActivationValues(tmpActivation);
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setErrors(validateForm(activationValues));
        setGlobalActivation(activationValues);
    }, [activationValues]);

    useEffect(() => {
        let _errors = validateForm(activationValues);
        if(_errors.endTime === undefined){            
            // Check if an activation is already present
            api('/api/orders/jobs/isActivationPresent', {
                params: {
                    machineId: activationValues.machine.id,
                    timespanStart: moment(activationValues.time)
                                    .seconds(0)
                                    .milliseconds(0)
                                    .toISOString(),
                    timespanEnd: activationValues.endTime ? 
                                    moment(activationValues.endTime)
                                        .second(0)
                                        .milliseconds(0)
                                        .toISOString()
                                :   moment()
                                        .seconds(0)
                                        .milliseconds(0)
                                        .toISOString(),
                },
                method: 'get',
            })
            .then((response) => {
                if (response.status === 200 && response.data === true) {
                    setErrors({..._errors, activationPresent: phrases.modules.order_export.errors.activationAlreadyPresent});
                    createAlert(phrases.modules.order_export.errors.activationAlreadyPresent, 'error');
                } else {
                    delete _errors.activationPresent;
                    setErrors({..._errors});
                }
            }).catch((error) => {
                setErrors(_errors)
            });
        } else {
            setErrors(_errors);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activationValues.time, activationValues.endTime]);

    const handleChange = (event) => {
        const { name, value } = event.target;        
        if(name === "isSetup"){
            setActivationValues({ ...activationValues, isSetup: event.target.checked});
        }        
        else {
            setActivationValues({ ...activationValues, [name]: value });
        }
    };

    const handleChangeHandCount = (hc) => {

        if(hc.target !== undefined){
            // event
            hc = hc.target.value;
        }
        if (Number.isNaN(Number(hc))) {
            setHandCount('');
            setActivationValues({ ...activationValues, handCount: '' });
            return;
        }
        setHandCount(hc);
        checkForHandcount({ ...activationValues, handCount: hc });
        setActivationValues({ ...activationValues, handCount: hc });
    };

    const handleChangeStartTime = (date) => {
        checkForHandcount(activationValues);
        setActivationValues({ 
            ...activationValues, 
            time: moment(date)
                    .seconds(0)
                    .milliseconds(0)
                    .toISOString() 
        });
    };
    const handleChangeEndTime = (date) => {
        checkForHandcount(activationValues);
        setActivationValues({ 
            ...activationValues, 
            endTime: moment(date)
                        .seconds(0)
                        .milliseconds(0)
                        .toISOString() 
        });
    };

    const handleClearForm = () => {
        setFormSubmitting(false);
        setErrors({});
        setActivationValues({...emptyActivation, machine: selectedMachine, job: job});
    };

    const checkForHandcount = (values) => {
        api('/api/orders/jobs/producedParts', {
            params: {
                machineId: values.machine.id,
                timespanStart: moment(values.time).format(),
                timespanEnd: values.endTime? moment(values.endTime).format(): moment().format(),        
            },
            method: "get"
        }).then((response) => {
            if (response.status === 200) {
                if(response.data?.actualCount === 0){
                    createAlert(phrases.modules.order_export.noPartsProduced, 'warning');
                }
                else if(values.handCount / response.data?.actualCount > HANDCOUNT_THRESHOLD){
                    createAlert(phrases.modules.order_export.handCountWarning, 'warning');
                }
            }
        }).catch((error) => {
            console.log(error);
        }
        );
    }

    const handleCloseConfirmationModal = () => {
        setConfirmationModalOpen(false);
    }

    const handleConfirmIntendedMachine = () => {
        // setErrors({...errors, intendedMachine: 0});
        setErrors(validateForm(activationValues));
        setConfirmationModalOpen(false);
    }
    
    const errorsPresent = () => {
        if(errors.intendedMachine !== undefined){
            return Object.keys(errors).length > 1;
        }
        return Object.keys(errors).length > 0;
    }
    
    useEffect(() => {
        setConfirmationModalOpen(errors.intendedMachine !== undefined && errors.intendedMachine > 0);
    }, [errors.intendedMachine]);

    const validateForm = (values, lite=false) => {
        let _errors = {};
        if(errors.activationPresent !== undefined){
            _errors.activationPresent = errors.activationPresent;
        }
        
        if (!Object.keys(CompletionStatusEnum).includes(values.activationStatus)) {
            _errors.activationStatus = phrases.modules.order_export.errors.activationStatusRequired;
        }
        // if (!values.user || _.isEmpty(values.user) || !values.user.email || values.user.email === '') {
        //     _errors.user = phrases.modules.order_export.errors.operatorRequired;
        // }
        if (values.handCount === null || values.handCount === undefined || values.handCount === '' || values.handCount < 0) {
            _errors.handCount = phrases.modules.order_export.errors.handCountRequired;
        }
        if (!values.time || moment(values.time).isAfter(moment())) { 
            _errors.time = phrases.modules.order_export.errors.startTimeRequired;
        }
        if ((!values.endTime && (values.activationStatus !== CustomCompletionStatusEnum.ACTIVE && values.activationStatus !== CompletionStatusEnum.STARTED))) {
            _errors.endTime = phrases.modules.order_export.errors.endTimeRequired;
        }
        if (values.endTime && moment(values.endTime).isBefore(moment(values.time))) {
            _errors.endTime = phrases.modules.order_export.errors.endTimeBeforeStartTime;
        }
        if (values.endTime && moment(values.endTime).isAfter(moment())) {
            _errors.endTime = phrases.modules.order_export.errors.endTimeInFuture;
        }
        if (!values.job || _.isEmpty(values.job)) {
            _errors.job = phrases.modules.order_export.errors.jobRequired;
        }
        if (!values.machine || _.isEmpty(values.machine)) {
            _errors.machine = phrases.modules.order_export.errors.machineRequired;
        }
        if(!lite){
            if(values.job?.intendedMachine !== values.machine?.businessId){
                if(errors.intendedMachine === undefined){
                    _errors.intendedMachine = 1;
                } else if (errors.intendedMachine === 1){
                    _errors.intendedMachine = 0;
                }
                else if(errors.intendedMachine === 0){
                    delete _errors.intendedMachine;
                }
            }
        }
        return _errors;
    }
    
    
    return {
        handleChange,
        handleChangeStartTime,
        handleChangeEndTime,
        handleClearForm,
        formSubmitting,
        errors,
        activationValues,
        toDateDisabled,
        selectedMachine,
        jobInformation,
        handCount,
        handleChangeHandCount,
        handleConfirmIntendedMachine,
        handleCloseConfirmationModal,
        confirmationModalOpen,
        validateForm,
        errorsPresent,
    };
}
 
export default useReviewFlowRecord;