import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import useApi from '@frontend/utils/useApi';
import _ from 'lodash';

const useTopReasons = (machines) => {
  const [reasons, setReasons] = useState([]);
  const [loadingReasons, setLoadingReasons] = useState(false);
  const [machineIds, setMachineIds] = useState([]);
  const [reasonTimes, setReasonTimes] = useState({});
  const [machineNames, setMachineNames] = useState({});
  const [reasonTotalCount, setReasonTotalCount] = useState({});
  const timespanStart       = useSelector((state) => state.timespanStart);
  const timespanEnd         = useSelector((state) => state.timespanEnd);
  const structure           = useSelector((state) => state.structure);
  
  const machineInUse = useSelector((state) => state.machineInUse);
  const _machine = useSelector((state) => state.machine);
  const api = useApi();

  // ===== Effects =====
  useEffect(() => {
      // Here we match the given Machines to their IDs as an array to pass them to the API
      if(machines !== undefined){
          // we got a predefined machines
          setMachineIds(machines);
          setMachineNames({[machineInUse]:{name: _machine.name, structure: _machine.structure}})
      } else {
        setMachineIds(structure?.machines.map(machine => machine.id));
        let newMachineNames = {};
        structure.machines.forEach(machine => {
          newMachineNames[machine.id] = {name: machine.name, structure: machine.structure}
        });
        setMachineNames(newMachineNames);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [structure, machines]);
  
  useEffect(() => {
    if(!loadingReasons){
      setLoadingReasons(true);
      api('/api/reasons/reasons-distribution', {
        method: 'get',
        params: {
          machines: machineIds || [],
          timespanEnd,
          timespanStart,
        },
      })
        .then((response) => {
          setReasons(_.isEmpty(response.data) ? [] : response.data.sortedReasons);
          setReasonTimes(_.isEmpty(response.data) ? {} : response.data.reasonTimes);
          if(!_.isEmpty(response.data) && !_.isEmpty(response.data.reasonTimes)){
            let tmpReasonTotalCount = {};
            let reasonNames = Object.keys(response.data.reasonTimes);          
            reasonNames.forEach((reason) => {
              let reasonCount = response.data.reasonTimes[reason].reduce((prev, curr) => {
                return prev + curr.duration.count;
              }, 0);
              tmpReasonTotalCount[reason] = reasonCount;
            });
            setReasonTotalCount(tmpReasonTotalCount);
          }
          setLoadingReasons(false);
      })
        .catch(() => {
          setLoadingReasons(false);
      }); 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machineIds, timespanStart, timespanEnd]);

  return {
    reasons,
    reasonTimes,
    loadingReasons,
    machineNames,
    structure,
    reasonTotalCount,
  };
};

export default useTopReasons;
