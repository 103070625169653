import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useApi from '@frontend/utils/useApi';

const useTopQualityLosses = (machines) => {
  const [qualityLosses, setQualityLosses] = useState([]);
  const [loadingQualityLosses, setLoadingQualityLosses] = useState(false);
  const [machineIds, setMachineIds] = useState([]);

  const timespanStart       = useSelector((state) => state.timespanStart);
  const timespanEnd         = useSelector((state) => state.timespanEnd);
  const structure           = useSelector((state) => state.structure);
  const api = useApi();

  // ===== Effects =====
  useEffect(() => {
    // Here we match the given Machines to their IDs as an array to pass them to the API
    if(machines !== undefined){
        // we got a predefined machines
        setMachineIds(machines);
    } else {
        setMachineIds(structure?.machines.map(machine => machine.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [structure, machines]);

  useEffect(() => {
    if(!loadingQualityLosses){
      setLoadingQualityLosses(true);
          
      api('/api/quality/quality-losses-distribution', {
        method: 'get',
        params: {
          machines: machineIds || [],
          timespanStart,
          timespanEnd,
        },
      })
      .then((response) => {
        setQualityLosses(response.data);
        setLoadingQualityLosses(false);
      })
      .catch((error) => {
        console.log(error);
        setLoadingQualityLosses(false);
      });        
    }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machineIds, timespanStart, timespanEnd]);

  return {
    qualityLosses,
    loadingQualityLosses,
  };
};

export default useTopQualityLosses;
