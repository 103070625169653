import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';

import moment from 'moment';
import {
    axisBottom,  
    select,
    scaleTime,
} from 'd3';
import useBreakpoint      from '@frontend/hooks/useCurrentBreakpoint';
import useStatesFilter    from '@frontend/hooks/useStatesFilter';
import MachineStatePopperDetailed from '@frontend/modules/machine/MachineStatePopperDetailed';
import MachineStatePopperGrouped  from '@frontend/modules/machine/MachineStatePopperGrouped';
import { usePhrases }     from '@frontend/utils/usePhrases';
import useTicks from '@frontend/modules/machine/hooks/useTicks';

const usePulseStates = (props) => {
    const {
        canMergeState, 
        canSplitState,
        data, 
        pulseOrders, 
        dimensions, 
        svgRef, 
        machine,
        overview,
        refreshPulse,
        customTimespanStart,
        customTimespanEnd,
        cleanPulseLayout
    } = props
    // ===== State Variables =====
    const [rect, setRect] = useState(null);
        // Popups
    const [popupOpen, setPopupOpen] = useState(false);
    const [orderPopupOpen, setOrderPopupOpen] = useState(false);
        // Selections
    const [selectedState, setSelectedState] = useState({});
    const [selectedOrder, setSelectedOrder] = useState({});
        // Modals
    const [reasonAssignModalOpen, setReasonAssignModalOpen] = useState(false);
    const [scrapReportModalOpen, setScrapReportModalOpen] = useState(false);
    const [splitStateModalOpen, setSplitStateModalOpen] = useState(false);
    const [mergeStateModalOpen, setMergeStateModalOpen] = useState(false);
        // Other
    const [isEdit, setIsEdit] = useState(false);
        // This flag determines, whether to show the ReasonAssignModal or the DrillDownMenu
    const [timeFrameDetailed, setTimeFrameDetailed] = useState(false);
    const [selectedTimespanStart, setSelectedTimespanStart] = useState(moment());
    const [selectedTimespanEnd, setSelectedTimespanEnd] = useState(moment());
    const [selectedTimespanDuration, setSelectedTimespanDuration] = useState(0);

    // ===== Redux Selectors =====
    const timespanStart         = useSelector((state) => state.timespanStart);
    const timespanEnd           = useSelector((state) => state.timespanEnd);
    const timespanDuration      = useSelector((state) => state.timespanDuration);
    const isRelativeTimespan    = useSelector((state) => state.isRelativeTimespan);
    const selectedRelativeTimespan = useSelector((state) => state.selectedRelativeTimespan);

    // ===== Imports =====
    const currentBreakpoint = useBreakpoint();
    const phrases = usePhrases().phrases();
    const {
        ticksTime,
        multiFormat
    } = useTicks(phrases.timespanSelection.localeDef);

    const {
        stateColor,
    } = useStatesFilter();

    // ===== Effects =====
    useEffect(() => {
      // If the timespan is custom, the timespanStart and timespanEnd are not overwritten 
      if(customTimespanEnd !== undefined && customTimespanStart !== undefined){
        setSelectedTimespanStart(moment(customTimespanStart).toDate());
        setSelectedTimespanEnd(moment(customTimespanEnd).toDate());
        setSelectedTimespanDuration(moment(customTimespanEnd).diff(moment(customTimespanStart), 'hours'));
      } else{
        setSelectedTimespanStart(timespanStart);
        setSelectedTimespanEnd(timespanEnd);
        setSelectedTimespanDuration(timespanDuration);
      }

    }, [customTimespanStart, customTimespanEnd, timespanStart, timespanEnd]);
    useEffect(() => {
        // Lets see if the timeframe lies under 24h. Then we can display the ReasonAssignModal
        if(isRelativeTimespan){
            switch (selectedRelativeTimespan.unit) {
            case "hours":
                setTimeFrameDetailed(parseInt(selectedRelativeTimespan.amount) <= 24)
                break;
            case "minutes":
                setTimeFrameDetailed(parseInt(selectedRelativeTimespan.amount) <= (60*24));
                break;   
            default:
                setTimeFrameDetailed(false); 
                break;
            }
        } else {
            // Absolute Timespan
            setTimeFrameDetailed(parseInt(selectedTimespanDuration) <= 24)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRelativeTimespan, selectedTimespanDuration, isRelativeTimespan]);
    
    useEffect(() => {
      updatePulse();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pulseOrders, data, dimensions, selectedTimespanDuration, selectedTimespanStart, selectedTimespanEnd]);
    
    // ===== Helper Methods =====
    const onStateClick = (_rect, state, nextState) => {
        setRect(_rect);
        setSelectedState({
          machineId: _rect.id,
          machineType: machine.type,
          startTime: moment(state.time),
          endTime: nextState ? moment(nextState.time) : moment(selectedTimespanEnd).startOf('minute'),
          state,
        });
        setPopupOpen(true);
      };
    
      function updatePulse() {
        if (!dimensions || !data) return;
        
        const svg = select(svgRef.current);
        // Erase chart so it can be redrawn with new data
        svg.selectAll('rect')
          .remove();
        // If the wrapper has no width an height
        // Min and Max time on the timeline
        const minTime = selectedTimespanStart;
        const maxTime = selectedTimespanEnd;
    
        // x scale, time scale
        const xScale = scaleTime()
          .domain([minTime, maxTime])
          .range([0, dimensions.width]);
    
        // x axis
        const xAxisBottom = axisBottom(xScale)
          .ticks(ticksTime(selectedTimespanDuration))
          .tickFormat(multiFormat);
    
        // position x axis
        const xAxis = svg.select('.x-axis')
          .transition()
          .duration(250)
          .style('transform', `translateY(${dimensions.height - 7}px)`)
          .call(xAxisBottom);
    
        if (currentBreakpoint()
          .down('sm')) {
          xAxis
            .selectAll('text')
            .style('text-anchor', 'end')
            .attr('dx', '-.8em')
            .attr('dy', '.15em')
            .attr('transform', 'rotate(-45)');
        } else if (currentBreakpoint()
          .up('sm')) {
          xAxis
            .selectAll('text')
            .style('text-anchor', 'end')
            .attr('dx', '1.46em')
            .attr('y', 9)
            .attr('dy', '0.71em')
            .attr('transform', 'rotate(0)');
        }
        svg.selectAll('rect')
          .data(data)
          .enter()
          .append('rect')
          .attr('id', machine.id)
          .attr('timestamp', (state) => state.time.toString())
          .attr('x', (state) => xScale(moment(state.time).isBefore(moment(minTime)) ? new Date(minTime) : new moment(state.time).toDate()))
          .attr('width', (state, i) => {
            const stateStart = xScale(
              moment(state.time).isBefore(moment(minTime))? 
                new Date(minTime)
              : moment(state.time).toDate());
            const stateEnd = xScale(data[i + 1]?
                moment(data[i + 1].time).toDate()   // Data available
              : new Date(maxTime));                 // Use the border
            const width = stateEnd - stateStart;
            return width < 0 ? 0 : width;
          })
          .attr('height', `${dimensions.height / 1.21}px`)
          .attr('fill', (state) => (stateColor(state, true)))
          .on('click', function (event, state) {
            const index = data.indexOf(state);
            onStateClick(event.currentTarget, state, data[index + 1]);
          })
          .on('mouseover', function (event) {
            select(event.currentTarget)
              .transition()
              .duration('100')
              .attr('fill', (state) => (`${stateColor(state, true)}DD`))
              .style('cursor', 'pointer');
          })
          .on('mouseout', function (event) {
            select(event.currentTarget)
              .transition()
              .duration('100')
              .attr('fill', (state) => (stateColor(state, true)));
          });
        // Finds the rect with same timestamp as current
        // so that the anchor el can be set
    
        pulseOrders.forEach((order, i) => {
          if(order.job !== "null" && order.duration >= 1){
            svg.append('rect')
            .attr('id', order.job+machine.id+"ordersStart")
            .attr('timestamp', moment(order.time).format())
            .attr('x', xScale(moment(order.time)
              .isSameOrBefore(moment(minTime)) ? new Date(minTime) : moment(order.time).toDate()))
            .attr('width', () => {
              const stateStart = (xScale(moment(order.time)
                .isSameOrBefore(moment(minTime))
                ? new Date(minTime)
                : moment(order.time).toDate()));
              const stateEnd = xScale(order.endTime !== null
                ? moment(order.endTime).toDate()
                : new Date(maxTime));
              const width = stateEnd - stateStart;
              return width < 0 ? 0 : width
            })
            .attr('height', `${dimensions.height/4}px`)
            .style('background-color', '#000000')
            .style('fill', i%2 ? "#c0defc": "#7c90a3")//'url(#diagonalHatch)': 'url(#diagonalHatch2)')
            .style('stroke-width', 0.5)
            .style('stroke', '#6e6e6e')
            .style('fill-opacity', 1)
            .on('click', function(event) {
              setPopupOpen(false);
              setRect(event.currentTarget);
              setSelectedOrder(order);
              setOrderPopupOpen(true);          
            })
            .on('mouseover', function (event) {
              select(event.currentTarget)
                .transition()
                .duration('100')
                .style('fill', i%2 ? "#9bb4cc": "#53616e")//'url(#diagonalHatchRev)': 'url(#diagonalHatch2Rev)' )
                .style('cursor', 'pointer');
            })
            .on('mouseout', function (event) {
              select(event.currentTarget)
                .transition()
                .duration('100')
                .style('fill', i%2 ? "#c0defc": "#7c90a3");//'url(#diagonalHatch)': 'url(#diagonalHatch2)')
            });  
          }
          else if(order.endTime === null){
            // Active Job
            svg.append('rect')
            .attr('id', order.job+machine.id+"activeJob")
            .attr('timestamp', moment(order.time).format())
            .attr('x', xScale(moment(order.time)
              .isSameOrBefore(moment(minTime)) ? new Date(minTime) : moment(order.time).toDate()))
            .attr('width', () => {
              const stateStart = (xScale(moment(order.time)
                .isSameOrBefore(moment(minTime))
                ? new Date(minTime)
                : moment(order.time).toDate()));
              const stateEnd = xScale(new Date(maxTime));
              const width = stateEnd - stateStart;
              return width < 0 ? 0 : width
            })
            .attr('height', `${dimensions.height/4}px`)
            .style('background-color', '#000000')
            .style('fill', "#00bfff")
            .style('stroke-width', 0.5)
            .style('stroke', '#0e85ad')
            .style('fill-opacity', 1)
            .on('click', function(event) {
              setPopupOpen(false);
              setRect(event.currentTarget);
              setSelectedOrder(order);
              setOrderPopupOpen(true);          
            })
            .on('mouseover', function (event) {
              select(event.currentTarget)
                .transition()
                .duration('100')
                .style('fill', "#0294c4")
                .style('cursor', 'pointer');
            })
            .on('mouseout', function (event) {
              select(event.currentTarget)
                .transition()
                .duration('100')
                .style('fill', "#00bfff");
            });  
          }
        })
        if (rect) {
          const rectArr = svg.selectAll(`[timestamp="${rect.attributes.timestamp.nodeValue}"]`);
          if (rectArr._groups[0]) { setRect(rectArr._groups[0][0]); }
        }
      }
      
      const renderPopup = () => {
        if(!overview || (overview && timeFrameDetailed)){
          // Machine View, only detailed
          return (
            <MachineStatePopperDetailed
              selectedState={selectedState}
              setIsEdit={setIsEdit}
              refreshPulse={refreshPulse}
              setReasonAssignModalOpen={setReasonAssignModalOpen}
              setScrapReportModalOpen={setScrapReportModalOpen}
              setSplitStateModalOpen={setSplitStateModalOpen}
              setMergeStateModalOpen={setMergeStateModalOpen}
              canSplitState={canSplitState}
              canMergeState={canMergeState}
              machine={machine}
              setPopupOpen={setPopupOpen}
              cleanPulseLayout={cleanPulseLayout}
            />)
        } else {
          return (
            <MachineStatePopperGrouped
              selectedState={selectedState}
              setIsEdit={setIsEdit}
              refreshPulse={refreshPulse}
              setReasonAssignModalOpen={setReasonAssignModalOpen}
              setScrapReportModalOpen={setScrapReportModalOpen}
              setSplitStateModalOpen={setSplitStateModalOpen}
              canSplitState={canSplitState}
              canMergeState={canMergeState}
              machine={machine}
              setPopupOpen={setPopupOpen}
            />)
        }
      }
    const handleClose = (e) => {
    if (scrapReportModalOpen || reasonAssignModalOpen || splitStateModalOpen) return;
    if (!rect) {
        setPopupOpen(false);
        setOrderPopupOpen(false);
    }
    if (e.target?.id === rect?.id) return;
    if (popupOpen) {
        setPopupOpen(false);
    }
    if (orderPopupOpen) {
        setOrderPopupOpen(false);
    }
    };

    const closeSplitStateModal = () => {
        setSplitStateModalOpen(false);
    };

    const closeMergeStateModal = () => {
        setMergeStateModalOpen(false);
    };

    return {
        onStateClick,
        updatePulse,
        renderPopup,
        handleClose,
        setPopupOpen,
        closeSplitStateModal,
        setReasonAssignModalOpen,
        closeMergeStateModal,
        popupOpen,
        orderPopupOpen,
        rect,
        selectedOrder,
        selectedState,
        reasonAssignModalOpen,
        scrapReportModalOpen,
        splitStateModalOpen, 
        mergeStateModalOpen, 
        isEdit
    };
}
 
export default usePulseStates;
