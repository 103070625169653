import React from 'react';
import {
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt} from '@fortawesome/free-solid-svg-icons';

import useSecurity      from '@frontend/utils/useSecurity';
import { usePhrases }   from '@frontend/utils/usePhrases';

const useStyles = makeStyles((theme) => ({    
    createButton: {
      borderRadius: '10%',
      padding: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    boldText: {
      fontWeight: 800,
    },
    lightText: {
        marginLeft: theme.spacing(1),
      fontWeight: 100,
      fontSize: '1.0rem',
    },
  }));
const LogoutButton = () => {

    const classes = useStyles();
    const phrases = usePhrases().phrases();    
    const { logout } = useSecurity();

    const handleLogout = () => {
      logout();
    };

    return (<>
     <IconButton
        aria-label="logoutBtn"
        aria-controls="menu-appbar"
        className={classes.createButton}
        onClick={handleLogout}
        color="inherit"
      >
        <FontAwesomeIcon
            icon={faSignOutAlt}
            size="0.5x"
            className={classes.lightText}
          />
        <span className={classes.lightText}>{phrases.nav.logout}</span>
      </IconButton>      
      </>
    );
}
 
export default LogoutButton;