import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
    Checkbox,
    FormControlLabel,
    Table,
    TableBody,
    TextField,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon     from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon                 from '@material-ui/icons/CheckBox';
import { usePhrases } from '@frontend/utils/usePhrases';
import useMachineFormCustomValues from './hooks/useMachineFormCustomValues';

const useStyles = makeStyles((theme) => ({
  inputField: {
    display: 'flex',
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingRight: 0,
    },
    margin: 0,
  },
  selectField: {
    marginRight: theme.spacing(1),
  },

  table: {
    margin: 0,
    marginTop: theme.spacing(2),
    padding: 0,
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  machineConfigLabel: {
    color: '#757575',
    fontSize: 16,
    paddingBottom: theme.spacing(1),
  },
  tableCell: {
    paddingTop: theme.spacing(1),
    textAlign: 'left',
    verticalAlign: 'middle',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      '&:nth-child(1)': {
        borderBottom: 'none',
      },
    },
  },
  rowWithMarginBottom: {
    paddingBottom: theme.spacing(1),
  }

}));
const MachineFormCustomValues = (props) => {
    const { 
        machineValues,
        setMachineValues 
    } = props;
    
    const { 
        handleChangeValue, 
        handleChangeCheckbox 
    } = useMachineFormCustomValues(machineValues, setMachineValues);
    const classes = useStyles();
    const phrases = usePhrases().phrases();
    return (<>    
        <TableContainer style={props.isEdit ? { width: '100%' } : {}} className={classes.table}>
            <Table size="small" padding="none" aria-label="simple table">
                <TableHead className={classes.rowWithMarginBottom} >
                    <TableRow className={classes.rowWithMarginBottom} >
                        <TableCell className={classes.machineConfigLabel} colSpan={2} align="left">{phrases.forms.machine.fields.machineExtendedConfiguration}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell className={classes.tableCell} component="td" scope="row">
                            <TextField
                                id="targetCycleTime"
                                label={phrases.forms.machine.configLabels.targetCycleTime}
                                variant="outlined"
                                name="targetCycleTime"
                                color="primary"
                                className={classes.inputField}
                                onChange={handleChangeValue}
                                value={machineValues.config?.targetCycleTime}
                                error={false}
                                margin="dense"
                                helperText={phrases.forms.machine.configUnits.targetCycleTime}
                                required
                            />
                        </TableCell>
                        <TableCell className={classes.tableCell} component="td" scope="row">
                          
                        <FormControlLabel
                            label={phrases.forms.machine.configLabels.partsChartSimple}
                            control={<Checkbox
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                style={{ marginRight: 8 }}
                                checked={machineValues.config?.partsChartSimple === 'true'}  // Backward compatibility
                                onChange={handleChangeCheckbox}
                                color="primary"
                                name="partsChartSimple"
                            />}
                            />
                        </TableCell>                   
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.tableCell} component="td" scope="row">
                          <FormControlLabel
                            label={phrases.forms.machine.configLabels.easyScrap}
                            control={<Checkbox
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                style={{ marginRight: 8 }}
                                checked={machineValues.config?.easyScrap === 'true'}  // Backward compatibility
                                onChange={handleChangeCheckbox}
                                color="primary"
                                name="easyScrap"
                            />}
                            />
                        </TableCell>     
                        <TableCell className={classes.tableCell} component="td" scope="row">
                        <FormControlLabel
                            label={phrases.forms.machine.configLabels.handCountStartEnd}
                            control={<Checkbox
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                style={{ marginRight: 8 }}
                                checked={machineValues.config?.handCountStartEnd === 'true'}  // Backward compatibility
                                onChange={handleChangeCheckbox}
                                color="primary"
                                name="handCountStartEnd"
                            />}
                            />
                        </TableCell>                   
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.tableCell} component="td" scope="row">
                          <FormControlLabel
                            label={phrases.forms.machine.configLabels.partsChart}
                            control={<Checkbox
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                style={{ marginRight: 8 }}
                                checked={machineValues.config?.partsChart === 'true'}  // Backward compatibility
                                onChange={handleChangeCheckbox}
                                color="primary"
                                name="partsChart"
                            />}
                            />
                        </TableCell>     
                        <TableCell className={classes.tableCell} component="td" scope="row">
                        <FormControlLabel
                            label={phrases.forms.machine.configLabels.jobBasedTerminal}
                            control={<Checkbox
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                style={{ marginRight: 8 }}
                                checked={machineValues.config?.jobBasedTerminal === 'true'}  // Backward compatibility
                                onChange={handleChangeCheckbox}
                                color="primary"
                                name="jobBasedTerminal"
                            />}
                            />
                        </TableCell>                   
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableCell} component="td" scope="row">
                        <TextField
                            id="microStop"
                            label={phrases.forms.machine.configLabels.microStop}
                            variant="outlined"
                            name="microStop"
                            color="primary"
                            className={classes.inputField}
                            onChange={handleChangeValue}
                            value={machineValues.config?.microStop}
                            error={false}
                            margin="dense"
                            helperText={phrases.forms.machine.configUnits.microStop}
                        />
                      </TableCell>  
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
        </>)
}
 
export default MachineFormCustomValues;