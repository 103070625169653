import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import useApi from '@frontend/utils/useApi';

const usePastActiveJobs = (machine) => {
    const [pastJobs, setPastJobs] = useState([]);
    const [loadingJobs, setLoadingJobs] = useState(false);

    // ===== Redux Selectors =====
    // const isRelativeTimespan = useSelector((state) => state.isRelativeTimespan);
    const timespanStart = useSelector((state) => state.timespanStart);
    const timespanEnd = useSelector((state) => state.timespanEnd);

    // ===== Imports =====
    const api = useApi();

    // ===== Effects =====
    useEffect(() => {
      if(!loadingJobs){
      setLoadingJobs(true);
      api(
          `/api/orders/jobs/pastJobs?machine=${machine}&start=${timespanStart}&end=${timespanEnd}`, 
          {
            method: 'get',
          },
        )
          .then((response) => {
            setPastJobs(response.data);
            // setPastJobs([{businessJobId: "lol", actualStartDate: "lllll"}, {businessJobId: "alol", actualStartDate: "aaa"}])
            setLoadingJobs(false);
          })
          .catch(() => {              
          });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [machine]);    
    return {
        pastJobs,
        loadingJobs
    };
}
 
export default usePastActiveJobs;