/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  DataGrid,
  GridToolbar,
  getGridStringOperators,
  getGridDateOperators,
} from '@mui/x-data-grid';
import BreadcrumbsNavigation from '@frontend/components/BreadcrumbsNavigation';
import _ from 'lodash';
import {
  Grid,
  Hidden,
  Paper,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  LinearProgress,
  Typography,
  Box,
  Collapse,
  IconButton,
  Chip,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';

import MuiDialogTitle from '@material-ui/core/DialogTitle';

import { Alert, AlertTitle } from '@material-ui/lab';

import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloseIcon from '@material-ui/icons/Close';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { ORDERS_TIMEFRAME_VALUES } from "@frontend/constants"
import { useSelector } from 'react-redux';
import moment from 'moment';
import useOrders from '@frontend/modules/orders/hooks/useOrders';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import useCsvUpload from '@frontend/modules/orders/hooks/useCsvUpload';
import { usePhrases } from '@frontend/utils/usePhrases';
import useDateTimeFormat from '@frontend/utils/useDateTimeFormat';
import OrderTimeFramePicker from './OrderTimeFramePicker';

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const useStyles = makeStyles((theme) => ({
  machinePulsesContainer: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  orange: {
    color: theme.palette.primary.main,
  },
  buttonProgress: {
    color: theme.palette.primary[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  uploadBox: {
    width: '100%',
    border: '1px solid grey',
    cursor: 'pointer',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  verticalContainer: {
    display: 'table',
  },
  verticallyAlignedText: {
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  errorContainer: {
    border: `2px solid ${theme.palette.warning.main}`,
    borderRadius: theme.spacing(0.5),
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  errorWrapper: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  root: {
    padding: theme.spacing(0.5, 0.5, 0),
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  textField: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    margin: theme.spacing(1, 0.5, 1.5),
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(0.5),
    },
    '& .MuiInput-underline:before': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
}));

const StyledChip = withStyles((theme) => ({
  root: {
    maxWidth: '100%',
  },
  label: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.text.primary,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))(Chip);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          size="small"
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function QuickSearchToolbar(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <GridToolbar />
      </div>
      <TextField
        variant="outlined"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? 'visible' : 'hidden' }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default function OrdersView() {
  const classes = useStyles();
  const phrases = usePhrases().phrases();
  const [openCsvUploadDialog, setOpenCsvUploadDialog] = useState(false);
  const [visibleUploadBox, setVisibleUploadBox] = useState(true);
  const [visibleErrorReport, setVisibleErrorReport] = useState(false);
  const [visibleSuccessReport, setVisibleSucessReport] = useState(false);
  const structureInUse = useSelector((state) => state.structureInUse);
  const [fullTimeSelect, setFullTimeSelect] = useState(false);
  const [timeFrameDisabled, setTimeFrameDisabled] = useState(fullTimeSelect);
  const [timeFrame, setTimeFrame] = useState(ORDERS_TIMEFRAME_VALUES.TWOWEEKS);
  
  const {
    csvFile,
    uploading,
    onFileChange,
    uploadFile,
    handleClearFile,
    handleClearUploadFeedback,
    uploadFeedback,
  } = useCsvUpload();
  const {
    jobs,
    loadingJobs,
    structure } = useOrders(structureInUse, fullTimeSelect, timeFrame);

  const { formatDate } = useDateTimeFormat();

  const columns = [
    { field: 'id', hide: true, filterable: false },
    {
      field: 'jobId',
      headerName: phrases.modules.orders.jobId,
      flex: 1,
      renderCell: (params) => (
        <Link to={`/orders/job/${params.value}`} className={classes.orange}>
          {params.value}
        </Link>
      ),
    },
    { field: 'orderId', headerName: phrases.modules.orders.orderId, flex: 1 },
    { field: 'status', headerName: phrases.modules.orders.status, flex: 1 },
    { field: 'product', headerName: phrases.modules.orders.product, flex: 1, valueGetter: (params) => params.value.name },
    /* {
      field: phrases.modules.orders.structureField,
      headerName: phrases.modules.orders.structure,
      flex: 1,
      valueGetter: (params) => params.value.name,
      renderCell: (params) => (
        <Tooltip title={structurePaths[params.row.structure.id]}>
          <Typography variant="body2" noWrap className={classes.orange}>
            <Link to={`/structure-overview/${params.row.structure.id}`} className={classes.orange}>
              {structurePaths[params.row.structure.id]}
            </Link>
          </Typography>
        </Tooltip>
      ),
      filterOperators: getGridStringOperators()
        .filter((operator) => operator.value === 'contains')
        .map((operator) => {
          return {
            ...operator,
            getApplyFilterFn: (filterItem) => {
              if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
                return null;
              }
              return ({ value }) => {
                const searchRegex = new RegExp(escapeRegExp(filterItem.value), 'i');
                return searchRegex.test(value);
              };
            },
          };
        }),
    }, */
    {
      type: 'string',
      field: phrases.modules.orders.intendedMachineField,
      headerName: phrases.modules.orders.intendedMachine,
      flex: 1,
      valueGetter: (params) => params.value.name,
      renderCell: (params) => (
        <Link to={`/machine-overview/${params.row.intendedMachine.id}`} className={classes.orange}>
          {params.value}
        </Link>
      ),
      filterOperators: getGridStringOperators()
        .filter((operator) => operator.value === 'contains')
        .map((operator) => {
          return {
            ...operator,
            getApplyFilterFn: (filterItem) => {
              if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
                return null;
              }
              return ({ value }) => {
                const searchRegex = new RegExp(escapeRegExp(filterItem.value), 'i');
                return searchRegex.test(value);
              };
            },
          };
        }),
    },
    {
      type: 'date',
      field: 'plannedStart',
      headerName: phrases.modules.orders.plannedStartDate,
      flex: 1,
      valueGetter: (params) => (params.value ? formatDate(params.value, false) : '-'),
      filterOperators: getGridDateOperators()
        .map((operator) => {
          return {
            ...operator,
            getApplyFilterFn: (filterItem) => {
              if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
                return null;
              }
              return ({ value }) => {
                switch (operator.value) {
                  case 'is': return moment(value).isSame(moment(filterItem.value));
                  case 'is not': return !moment(value).isSame(moment(filterItem.value));
                  case 'is after': return moment(value).isAfter(moment(filterItem.value));
                  case 'is on or after': return moment(value).isSameOrAfter(moment(filterItem.value));
                  case 'is before': return moment(value).isBefore(moment(filterItem.value));
                  case 'is on or before': return moment(value).isSameOrBefore(moment(filterItem.value));
                  default: return null;
                }
              };
            },
          };
        }),
    },
    {
      type: 'date',
      field: 'actualStart',
      headerName: phrases.modules.orders.actualStartDate,
      flex: 1,
      valueGetter: (params) => (params.value ? formatDate(params.value, false) : '-'),
      filterOperators: getGridDateOperators()
        .map((operator) => {
          return {
            ...operator,
            getApplyFilterFn: (filterItem) => {
              if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
                return null;
              }
              return ({ value }) => {
                switch (operator.value) {
                  case 'is': return moment(value).isSame(moment(filterItem.value));
                  case 'is not': return !moment(value).isSame(moment(filterItem.value));
                  case 'is after': return moment(value).isAfter(moment(filterItem.value));
                  case 'is on or after': return moment(value).isSameOrAfter(moment(filterItem.value));
                  case 'is before': return moment(value).isBefore(moment(filterItem.value));
                  case 'is on or before': return moment(value).isSameOrBefore(moment(filterItem.value));
                  default: return null;
                }
              };
            },
          };
        }),
    },
    {
      type: 'number',
      field: 'target',
      headerName: phrases.modules.orders.target,
      flex: 1,
    },
    {
      type: 'number',
      field: 'sensorCount',
      headerName: phrases.modules.orders.sensorCount,
      flex: 1,
    },
    {
      type: 'number',
      field: 'handCount',
      headerName: phrases.modules.orders.handCount,
      flex: 1,
    },
  ];

  useEffect(() => {
    if (openCsvUploadDialog) {
      if (csvFile) setVisibleUploadBox(false);
      if (!csvFile && !uploadFeedback?.numOfErrors) setVisibleUploadBox(true);
    }
  }, [csvFile, uploadFeedback, openCsvUploadDialog]);

  useEffect(() => {
    if (openCsvUploadDialog) {
      if (uploadFeedback?.numOfErrors) {
        setVisibleErrorReport(true);
        setVisibleUploadBox(false);
      } else {
        setVisibleErrorReport(false);
      }
    }
  }, [uploadFeedback, openCsvUploadDialog]);

  useEffect(() => {
    if (!_.isEmpty(uploadFeedback) && !uploadFeedback.numOfErrors) {
      setVisibleSucessReport(true);
      setVisibleUploadBox(false);
    } else {
      setVisibleSucessReport(false);
    }
  }, [uploadFeedback]);

  const handleCloseErrorReport = () => {
    setVisibleErrorReport(false);
    setVisibleUploadBox(true);
  };

  const handleCloseSuccessReport = () => {
    setVisibleSucessReport(false);
    setVisibleUploadBox(true);
  };

  const handleClickOpen = () => {
    setOpenCsvUploadDialog(true);
  };

  const handleClose = () => {
    setOpenCsvUploadDialog(false);
    handleClearFile();
    handleClearUploadFeedback();
  };

  const [searchText, setSearchText] = React.useState('');
  const [rows, setRows] = React.useState(jobs);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = jobs.filter((row) => {
      return Object.keys(row).some((field) => {
        if (field === phrases.modules.orders.intendedMachineField) return searchRegex.test(row[field].name);
        if (field === phrases.modules.orders.structureField) return searchRegex.test(row[field].name);
        return searchRegex.test(row[field]?.toString());
      });
    });
    setRows(filteredRows);
  };

  useEffect(() => {
    setRows(jobs);
  }, [jobs]);

  return (
    <Grid container spacing={2}>
      <Hidden xsDown>
        <Grid item xs={12}>
          <BreadcrumbsNavigation selectedStructure={structure} />
        </Grid>
      </Hidden>
      <Grid
        item
        xs={12}
        component={Paper}
        variant="outlined"
        className={classes.machinePulsesContainer}
        square
      >
        <Grid container spacing={2}>
          <OrderTimeFramePicker
          fullTimeSelect={fullTimeSelect}
          setFullTimeSelect={setFullTimeSelect}
          timeFrame={timeFrame}
          setTimeFrame={setTimeFrame}
          timeFrameDisabled={timeFrameDisabled}
          setTimeFrameDisabled={setTimeFrameDisabled}
          />
          <Grid item xs={4}>
            <Typography align="right">
              <Button
                endIcon={
                  <CloudUploadIcon>
                    {phrases.modules.orders.uploadCSV}
                  </CloudUploadIcon>
                }
                variant="contained"
                color="primary"
                onClick={handleClickOpen}
              >
                {phrases.modules.orders.uploadCSV}
              </Button>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <DataGrid
              autoHeight
              rows={rows}
              columns={columns}
              pageSize={10}
              disableColumnSelector
              disableColumnMenu
              disableSelectionOnClick
              components={{ Toolbar: QuickSearchToolbar }}
              componentsProps={{
                toolbar: {
                  value: searchText,
                  onChange: (event) => requestSearch(event.target.value),
                  clearSearch: () => requestSearch(''),
                },
              }}
              loading={loadingJobs}
            />
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={openCsvUploadDialog}
        onClose={handleClose}
        disableBackdropClick
        fullWidth
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {phrases.modules.orders.uploadCSV}
        </DialogTitle>
        <DialogContent style={{ overflow: 'visible' }}>
          <Grid container>
            <Grid item xs={12}>
              <Collapse in={visibleUploadBox}>
                <label htmlFor="btn-upload">
                  <Box className={classes.uploadBox}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography align="center">
                          <CloudUploadIcon fontSize="large" />
                        </Typography>
                        <Typography align="center">
                          {phrases.modules.orders.chooseFileToUpload}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography align="center">
                          <Button
                            className="btn-choose"
                            variant="outlined"
                            component="span"
                            color="primary"
                          >
                            {phrases.modules.orders.chooseFile}
                          </Button>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <input
                    id="btn-upload"
                    name="btn-upload"
                    hidden
                    type="file"
                    accept=".csv"
                    onChange={onFileChange}
                  />
                </label>
              </Collapse>
              <Collapse in={visibleErrorReport}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {uploadFeedback?.numOfErrors && (
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Alert
                            elevation={4}
                            severity="warning"
                            variant="filled"
                            className={classes.errorWrapper}
                          >
                            <AlertTitle>
                              <Typography>
                                {phrases.modules.orders.fileHasBeenUploaded}
                              </Typography>
                            </AlertTitle>
                            <Typography>
                              {
                                phrases.modules.orders
                                  .uploadAgainWithCorrections
                              }
                            </Typography>
                          </Alert>
                          <List
                            component={Paper}
                            elevation={4}
                            square
                            className={classes.errorContainer}
                          >
                            {Object.keys(uploadFeedback?.errors).map(
                              (errorRow) => (
                                <ListItem key={errorRow}>
                                  <ListItemText
                                    primary={
                                      <Typography>{`${phrases.modules.orders.row} ${errorRow}`}</Typography>
                                    }
                                    secondary={Object.keys(
                                      uploadFeedback.errors[errorRow],
                                    ).map((prop) => (
                                      <Typography
                                        style={{ marginLeft: 8 }}
                                        key={prop}
                                      >
                                        {uploadFeedback.errors[errorRow][prop]}
                                      </Typography>
                                    ))}
                                  />
                                </ListItem>
                              ),
                            )}
                          </List>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography align="center">
                      <Button
                        onClick={handleCloseErrorReport}
                        variant="contained"
                      >
                        {phrases.modules.orders.backToUploadFile}
                      </Button>
                    </Typography>
                  </Grid>
                </Grid>
              </Collapse>
              <Collapse in={visibleSuccessReport}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Alert
                      elevation={4}
                      severity="success"
                      variant="filled"
                      className={classes.errorWrapper}
                    >
                      <AlertTitle>
                        <Typography>File has been uploaded.</Typography>
                      </AlertTitle>
                      <Typography>{`${uploadFeedback?.valid} ${phrases.modules.orders.jobsCreatedSuccessfully}.`}</Typography>
                    </Alert>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography align="center">
                      <Button
                        onClick={handleCloseSuccessReport}
                        variant="contained"
                      >
                        {phrases.modules.orders.uploadAnotherFile}
                      </Button>
                    </Typography>
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
            <Grid item xs={12}>
              {csvFile && (
                <Grid container spacing={1}>
                  <Grid item xs={9}>
                    {!uploading ? (
                      <StyledChip
                        variant="outlined"
                        label={csvFile.name}
                        color="primary"
                        deleteIcon={<CloseIcon />}
                        onDelete={handleClearFile}
                        icon={<InsertDriveFileIcon />}
                      />
                    ) : (
                        <>
                          <Typography>{`${phrases.modules.orders.uploading}: ${csvFile.name}`}</Typography>
                          <LinearProgress />
                        </>
                      )}
                  </Grid>
                  <Grid item xs={3} className={classes.verticalContainer}>
                    <Typography
                      align="right"
                      className={classes.verticallyAlignedText}
                    >
                      <Button
                        className="btn-upload"
                        color="primary"
                        variant="contained"
                        component="span"
                        size="small"
                        disabled={uploading}
                        onClick={uploadFile}
                      >
                        {phrases.modules.orders.upload}
                      </Button>
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button disabled={uploading} onClick={handleClose} color="secondary">
            {phrases.modules.orders.close}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
