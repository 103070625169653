
import React from 'react';
import { 
    Grid, 
    Typography 
} from '@material-ui/core';
import StepperProgress      from '@frontend/components/StepperProgress'; 
import useReviewHelpFlow    from '@frontend/modules/orders/hooks/useReviewHelpFlow';
import { usePhrases }       from '@frontend/utils/usePhrases';

import { 
    makeStyles
} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(0),
        },
    },    
    paper: {
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1),
        // textAlign: 'center',
        color: theme.palette.text.secondary,
    },   
    progress: {
        // textAlign: 'center',
        margin: 'auto',
    },
    backdrop: {
        zIndex: 1,
        position: 'fixed',
        // left: 0,
        width: 'calc(100% - 56px)',
        textAlign: 'center',
    },
}));

const ReviewFlowContent = (props) => {

    const { 
        handleClose,
        job,
        selectedMachine,        
        workers 
    } = props;

    const phrases = usePhrases().phrases();
    const steps = [
        phrases.modules.orders.reviewFlow.page1, 
        phrases.modules.orders.reviewFlow.page2, 
        phrases.modules.orders.reviewFlow.page3,
        phrases.modules.orders.reviewFlow.page4
    ];

    const {
        activeStep,
        handleBack,
        handleNext,
        handleReset,
        handleFinish,
        pageContent,
        activationFormSubmitting,
        qrFormSubmitting
    } = useReviewHelpFlow(steps, handleClose, job, selectedMachine, workers);

    const classes = useStyles();

    return (  <>
        <div className={classes.paper}>
            <Grid container spacing={1}>
                <Grid item xs={12} className={classes.progress}>
                    <Typography variant="h6" component="h6">
                        {pageContent}
                    </Typography>
                </Grid>
            </Grid>
            
            <StepperProgress 
                steps={steps} 
                activeStepIndex={activeStep}
                handleBack={handleBack}
                handleNext={handleNext}
                handleReset={handleReset} 
                handleFinish={handleFinish}
                finishLoading={activationFormSubmitting || qrFormSubmitting}
            />
        </div>
    </>);
}
 
export default ReviewFlowContent;