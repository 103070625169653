import React from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

// import {
//   DataGrid,
//   getGridDateOperators,
// } from '@material-ui/data-grid';
import { 
  DataGrid, 
  getGridDateOperators 
} from '@mui/x-data-grid';
import { 
  makeStyles
} from '@material-ui/core/styles';
import {
  Button,
  Chip,
  Divider,
  Grid,
  Hidden,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import CheckBoxIcon                 from '@material-ui/icons/CheckBox';
import IndeterminateCheckBoxIcon    from '@material-ui/icons/IndeterminateCheckBox';
import useOrdersExportValidation  from '@frontend/modules/orders/hooks/useOrdersExportValidation';
import { usePhrases }             from '@frontend/utils/usePhrases';
import useDateTimeFormat          from '@frontend/utils/useDateTimeFormat';
import { ROUTES } from '../../../constants';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(0),
      },
    },
    orange: {
      color: theme.palette.primary.main,
    },
    selectField: {
      marginTop: theme.spacing(1.3),
      marginBottom: theme.spacing(1.1),
    },
    selectFieldMachine: {      
      marginTop: theme.spacing(1.3),
      marginBottom: theme.spacing(1.1),
      marginRight: theme.spacing(1),
      minWidth: 120,
      width: '100%',
      marginLeft: 'auto',     
    },
    paper: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(1),
      // textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    machinePulsesContainer: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      position: 'relative',
    },
    lastTableCell: {
      borderBottom: 'none',
    },
    pushDiv: {
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(9),
      },
      marginTop: theme.spacing(5),
    },
    topCardsContainer: {
      margin: theme.spacing(0),
      padding: theme.spacing(0),
    },
    cardCell: {
      padding: theme.spacing(1.5),
    },
    cardCellWithIcon: {
      padding: theme.spacing(1),
    },
    progress: {
      textAlign: 'center',
      margin: 'auto',
    },
    backdrop: {
      zIndex: 1,
      position: 'fixed',
      // left: 0,
      width: 'calc(100% - 56px)',
      textAlign: 'center',
    },
    button: { 
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1), 
    },
    guideButton: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1), 
      backgroundColor: theme.palette.info.main,
      color: theme.palette.info.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.info.dark,
      },
    },
    heading: {
        textAlign: 'center',
        margin: 'auto',
        marginBottom: theme.spacing(2),
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    },


  }));
const ReviewFlowOverview = (props) => {
    const { structureId, selectedMachine } = props;
    const classes = useStyles();
    const phrases = usePhrases().phrases();
    const {         
        selectedActivations,
        loadingOrders,
        handleEditActivation,
    } = useOrdersExportValidation(structureId);

    const { formatShortDate } = useDateTimeFormat();
    const history = useHistory();
    const columns = [
        { field: 'activationStatus', 
        headerName: phrases.modules.order_export.activationStatus, 
        flex: 1,
        valueGetter: (params) => (params.value ? phrases.modules.orders[params.value] : "-")},
        { 
        field: 'isSetup', 
        headerName: phrases.modules.order_export.isSetup, 
        flex: 1,
        renderCell: (params) => (params.value ? <CheckBoxIcon fontSize="small" />: <IndeterminateCheckBoxIcon fontSize='small'/>)
        },
        { 
        field: 'user', 
        headerName: phrases.modules.order_export.user, 
        flex: 1,
        valueGetter: (params) => (params.value.businessUserId ? params.value.businessUserId : params.value.email ? params.value.email: '-')
        },
        { field: 'handCount', headerName: phrases.modules.order_export.handCount, flex: 1 },
        { 
        field: 'partsProduced', 
        headerName: phrases.modules.order_export.partsProduced, 
        flex: 1,
        valueGetter: (params) => (params.value ?? 0)
        },
        { 
        field: 'totalScrap', 
        headerName: phrases.modules.order_export.totalScrap, 
        flex: 1,
        valueGetter: (params) => (params.value ?? 0)
        },
        {
        type: 'date',
        field: 'time',
        headerName: phrases.modules.order_export.startDate,
        flex: 1,
        valueGetter: (params) => (params.value ? formatShortDate(params.value, true) : '-'),
        filterOperators: getGridDateOperators()
            .map((operator) => {
            return {
                ...operator,
                getApplyFilterFn: (filterItem) => {
                if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
                    return null;
                }
                return ({ value }) => {
                    switch (operator.value) {
                    case 'is': return moment(value).isSame(moment(filterItem.value));
                    case 'is not': return !moment(value).isSame(moment(filterItem.value));
                    case 'is after': return moment(value).isAfter(moment(filterItem.value));
                    case 'is on or after': return moment(value).isSameOrAfter(moment(filterItem.value));
                    case 'is before': return moment(value).isBefore(moment(filterItem.value));
                    case 'is on or before': return moment(value).isSameOrBefore(moment(filterItem.value));
                    default: return null;
                    }
                };
                },
            };
            }),
        },
        {
        type: 'date',
        field: 'endTime',
        headerName: phrases.modules.order_export.endDate,
        flex: 1,
        valueGetter: (params) => (params.value == null ? "-": formatShortDate(moment(params.value), true)),
        filterOperators: getGridDateOperators()
            .map((operator) => {
            return {
                ...operator,
                getApplyFilterFn: (filterItem) => {
                if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
                    return null;
                }
                return ({ value }) => {
                    switch (operator.value) {
                    case 'is': return moment(value).isSame(moment(filterItem.value));
                    case 'is not': return !moment(value).isSame(moment(filterItem.value));
                    case 'is after': return moment(value).isAfter(moment(filterItem.value));
                    case 'is on or after': return moment(value).isSameOrAfter(moment(filterItem.value));
                    case 'is before': return moment(value).isBefore(moment(filterItem.value));
                    case 'is on or before': return moment(value).isSameOrBefore(moment(filterItem.value));
                    default: return null;
                    }
                };
                },
            };
            }),
        },
        {
        type: 'string',
        field: 'job',
        headerName: phrases.modules.order_export.job,
        flex: 1,
        renderCell: (params) => (
            <Link to={`/orders/job/${params.value.businessJobId}`} className={classes.orange}>
            {params.value.businessJobId ?? '-'}
            </Link>
        ),
        },
        {
        type: 'string',
        field: 'machine',
        headerName: phrases.modules.order_export.machine,
        flex: 1,
        renderCell: (params) => (
            <Link to={`/machine-overview/${params.value.id}`} className={classes.orange}>
            <Chip color="primary" key={params.value.id} className={classes.chip} label={params.value.name} />
            </Link>
        )
        },
    ];
    return ( <>
        <div className={classes.root}>
            <Grid container spacing={2} className={classes.root}>                                 
                <Grid item xs={12} sm={12}>
                    <Typography variant="h5" component="h5" className={classes.heading}>
                        {phrases.modules.orders.reviewFlow.activationOverview}
                        {" - "}
                        {phrases.modules.orders.reviewFlow.machine}
                        {" "}
                        {selectedMachine.name}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Paper variant="outlined" square>
                    <Button
                        color="primary"
                        size="small"
                        className={classes.button}
                        variant="contained"
                        aria-label="select merge strategy"
                        onClick={(e) => {history.push(`${ROUTES.ORDERS_EXPORT_VALIDATION}/${selectedMachine.structure}`)}}
                    >
                        {phrases.modules.order_export.addActivation}
                    </Button>
                    <Button
                        size="small"
                        className={classes.guideButton}
                        variant="contained"
                        aria-label="select merge strategy"
                        onClick={(e) => {history.push(`${ROUTES.REVIEW_FLOW}`)}} 
                        >
                        {phrases.modules.orders.reviewFlow.guide}
                    </Button>
                    <DataGrid
                        autoHeight
                        rows={selectedActivations}
                        columns={columns}
                        onRowDoubleClick={(row) => handleEditActivation(row.row)}
                        pageSize={10}
                        disableColumnSelector
                        disableColumnMenu
                        disableSelectionOnClick
                        loading={loadingOrders}
                        sx={{
                        ".MuiDataGrid-iconButtonContainer": {
                            visibility: 'hidden !important',
                            opacity: '0 !important',
                        },
                        ".MuiDataGrid-sortIcon":{
                            visibility: 'hidden !important',
                            opacity: '0 !important',
                            transition: 'none !important',
                        }

                        }}
                    />
                    </Paper>
                </Grid>
            </Grid>
        </div>        
        
    </> );
}
 
export default ReviewFlowOverview;