import React, { useEffect, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';

import ReviewFlowPulse          from '@frontend/modules/orders/components/ReviewFlowPulse';
import ReviewFlowQualityReport  from '@frontend/modules/orders/components/ReviewFlowQualityReport';
import ReviewFlowRecord         from '@frontend/modules/orders/components/ReviewFlowRecord';
import ReviewFlowSummary        from '@frontend/modules/orders/components/ReviewFlowSummary';
import useAlert                 from '@frontend/hooks/useAlert'
import useApi                   from '@frontend/utils/useApi';
import { usePhrases }           from '@frontend/utils/usePhrases';

const useReviewHelpFlow = (steps, handleClose, job, selectedMachine, workers) => {

    const [activeStep, setActiveStep] = useState(0);
    const [pageContent, setPageContent] = useState(<> </>);
    const [globalActivation, setGlobalActivation] = useState({});
    const [globalErrors, setGlobalErrors] = useState({});
    const [scrapPresent, setScrapPresent] = useState(true);
    const [globalQRData, setGlobalQRData] = useState({});

    const [activationFormSubmitting, setActivationFormSubmitting] = useState(false);
    const [qrFormSubmitting, setQrFormSubmitting] = useState(false);
    const api = useApi();
    const phrases = usePhrases().phrases();
    const { createAlert } = useAlert();
    
    useEffect(() => {
        switch(activeStep) {
            case 0:
                setPageContent(renderFirstPage());
                break;
            case 1:
                setPageContent(renderSecondPage());
                break;
            case 2:
                setPageContent(renderThirdPage());
                break;
            case 3:
                setPageContent(renderFourthPage());
                break;
            default:
                setPageContent(renderFirstPage());
        }
    }, [activeStep]);

    const errorsPresent = (errors) => {
        if(errors.intendedMachine !== undefined){
            return Object.keys(errors).length > 1;
        }
        return Object.keys(errors).length > 0;
    }

    const sendActivationData = () => {
        if(!_.isEmpty(globalActivation)
            && !errorsPresent(globalErrors)
            && !_.isEmpty(job)) {
            setActivationFormSubmitting(true);
            return api('/api/orders/jobs/machineActivations', {
                data: {                
                        ...globalActivation,
                        machine: selectedMachine.id,  
                        job: globalActivation.job.businessJobId, 
                        isWorkingOrderComplete: false,             
                },
                method: 'post',
            })
            .then((response) => {            
                setActivationFormSubmitting(false);  
                return true;
            }).catch((error) => {
                console.error(error);
                setActivationFormSubmitting(false);  
                return false;
            });
        }
        return false;
    }

    const sendQrData = () => {
        if(!_.isEmpty(globalQRData)
            && !errorsPresent(globalErrors)
            && !_.isEmpty(job)) {
            // First we create a new note for the qr
            return api('/api/shared/notes', {
                data: {
                    text: globalQRData.note 
                },
                method: 'post',
            })
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    // Now we create the qr
                    const noteId = response.data.id
                    return api('/api/quality/report', {
                        data: {
                            machine: job.intendedMachine.id,
                            oldQualityReportData: null,
                            qualityReportData: {
                                machineId: job.intendedMachine.id,
                                note: noteId,
                                wasteCount: globalQRData.value,
                                scrapType: globalQRData.identifier,
                                startTime: globalQRData.startTime,
                                endTime: globalQRData.endTime,
                            },
                        },
                        method: 'post',
                    })
                    .then((response) => {
                        setQrFormSubmitting(false);
                        return true;
                    })
                    .catch((error) => {
                        console.error(error);
                        setQrFormSubmitting(false);
                        return false;
                    });
                }
            }) 
        }
        return false;
    }

    const pageOneCallback = () => {
        // This is what is executed after the activation data is submitted
        if(!errorsPresent(globalErrors)) {
            // If there is no scrap, skip two pages
            if(!scrapPresent) {
                setActiveStep((prevActiveStep) => prevActiveStep + 2);
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }            
        }
    }

    const pageTwoCallback = () => {
        // Do useful things here
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    const pageThreeCallback = () => {
        // Do useful things here
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    const pageFourCallback = () => {
        // Do useful things here
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    const dispatchClickNext = (activeStep) => {
        switch(activeStep) {
            case 0:
                return pageOneCallback();
            case 1:
                return pageTwoCallback();
            case 2:
                return pageThreeCallback();
            case 3:
                return pageFourCallback();
            default:
                return pageOneCallback();
        }
    }
    const renderFirstPage = () => {
        return (
            <ReviewFlowRecord
                job={job}       
                globalActivation={globalActivation}
                setActivation={setGlobalActivation}
                setGlobalErrors={setGlobalErrors}
                workers={workers}
                selectedMachine={selectedMachine}
            />
        );
    }

    const renderSecondPage = () => {
        return (
            <ReviewFlowQualityReport
                machine={selectedMachine}
                activation={globalActivation}
                setQRData={setGlobalQRData}
                qrData={globalQRData}
            />
        );
    }

    const renderThirdPage = () => {
        return (
            <ReviewFlowPulse
                machine={selectedMachine}
                timeframeStart={globalActivation.time}
                timeframeEnd={globalActivation.endTime || moment()}
            />
        )
    }

    const renderFourthPage = () => {
        return (
            <ReviewFlowSummary
                qualityReports={globalQRData}
                activation={globalActivation}
                scrapPresent={scrapPresent}
            />
        );
    }

    const handleBack = () => {
        if(activeStep > 0) {
            // if we are on page 3, go back and have no scrap, we need to skip page 2
            if(activeStep === 2 && !scrapPresent) {
                setActiveStep((prevActiveStep) => prevActiveStep - 2);
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep - 1);
            }
        }
    }

    const handleNext = () => {  
        if(activeStep <= steps.length - 1) {         
            dispatchClickNext(activeStep);
        }
    }

    const handleReset = () => {
        setActiveStep(0);
    }

    const handleFinish = async () => {
        // Send the data to the backend
        let allOk = await sendActivationData();
        if(scrapPresent) {
            allOk = allOk && await sendQrData();
        }
        if(allOk){
            createAlert(phrases.modules.orders.reviewFlow.dataSaveSuccess, 'success');
        } else {
            createAlert(phrases.modules.orders.reviewFlow.dataSaveFail, 'error');
        }
        handleReset();
        handleClose();
    }

    return {
        activeStep,
        steps,
        handleNext,
        handleBack,
        handleReset,
        handleFinish,
        pageContent,
        activationFormSubmitting,
        qrFormSubmitting
    };
}
 
export default useReviewHelpFlow;