import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import {
  Divider,
  MenuItem,
  Menu,
} from '@material-ui/core';

import ReloadIntervalButton   from '@frontend/components/ReloadIntervalButton';
import EasyLoginCreatorButton from '@frontend/components/EasyLoginCreatorButton';
import LogoutButton           from '@frontend/components/LogoutButton';
import useSecurity            from '@frontend/utils/useSecurity';

const UserMenu = (props) => {
  const {
    userMenuAnchorEl,
    setUserMenuAnchorEl,
    open,
  } = props;

  const { getProfile } = useSecurity();
  const user = getProfile();

  const handleCloseUserMenu = () => {
    setUserMenuAnchorEl(null);
  };

  const StyledMenuItem = withStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
        cursor: 'default',
      },
    },
  })(MenuItem);

  return (
    <Menu
      id="menu-appbar"
      getContentAnchorEl={null}
      anchorEl={userMenuAnchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      open={open}
      onClose={handleCloseUserMenu}
      PaperProps={{
        square: true,
        variant: 'outlined',
      }}
    >
      <StyledMenuItem><b>{user.name}</b></StyledMenuItem>
      <StyledMenuItem>{user.email}</StyledMenuItem>
      <Divider />
      <StyledMenuItem>
        <ReloadIntervalButton />
      </StyledMenuItem>
      <Divider />
      <StyledMenuItem>
        <EasyLoginCreatorButton />
      </StyledMenuItem>
      <Divider />
      <StyledMenuItem>
        <LogoutButton />
      </StyledMenuItem>
    </Menu>
  );
};

UserMenu.propTypes = {
  setUserMenuAnchorEl: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default UserMenu;
